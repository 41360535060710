import React, { ImgHTMLAttributes, useEffect, useRef, useState } from "react";
import {
  Emart,
  Gkl,
  Gungi,
  Hyu,
  Incheonairport,
  Incheontourism,
  incheonportauthority,
  KbBank,
  Kbinvestment,
  Korea_tourism,
  Kpc,
  Kreao,
  Kstartup,
  Kvic,
  Kvic2,
  Newborn,
  SevenLuck,
  Thehyundai,
  ezl,
  Hyundai,
} from "const/imageImport";
import Background from "asset/image/Background.png";
import "./patner.css";
import MessageIcon from "asset/image/messageIcon.png";
import {
  fontSize32,
  fontSize24,
  leading50,
  fontSize30,
  fontSize34,
  fontSize36,
} from "const/fontSize";
import string from "locales";
import InqueryBanner from "component/common/InqueryBanner";
import { LanguageCode } from "types/Type";
export default function MobilePartnerShip({
  userLanguage,
}: {
  userLanguage: LanguageCode;
}) {
  const array1 = [
    Kvic,
    Korea_tourism,
    Incheonairport,
    SevenLuck,
    ezl,
    Hyundai,
    Gkl,
    incheonportauthority,
    Kreao,
  ];
  const array2 = [
    Gungi,
    Incheontourism,
    Newborn,
    KbBank,
    Kbinvestment,
    Thehyundai,
    Emart,
    Kvic2,
    Hyu,
  ];
  const [scrollPosition, setScrollPosition] = useState(0);
  const [scorllPosition2, setScrollPosition2] = useState(450);
  const scrollRef = useRef<HTMLDivElement>(null);
  const scrollRef2 = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const scrollContainer = scrollRef.current;
    if (scrollContainer) {
      const scroll = () => {
        setScrollPosition((prevPosition) => {
          const newPosition = prevPosition + 1;
          if (newPosition >= scrollContainer.scrollWidth / 2) {
            return 0;
          }
          return newPosition;
        });
      };

      const intervalId = setInterval(scroll, 20); // Adjust for faster/slower scrolling

      return () => clearInterval(intervalId);
    }
  }, []);
  useEffect(() => {
    const scrollContainer = scrollRef2.current;
    if (scrollContainer) {
      const scroll = () => {
        setScrollPosition2((prevPosition) => {
          const newPosition = prevPosition + 1;

          if (newPosition >= scrollContainer.scrollWidth / 2) {
            return 0;
          }
          return newPosition;
        });
      };

      const intervalId = setInterval(scroll, 20); // Adjust for faster/slower scrolling

      return () => clearInterval(intervalId);
    }
  }, []);

  return (
    <div className=" relative flex h-[calc(100vh-75px)] flex-col bg-[#EFF2F4] items-center">
      <div className={`${fontSize36}  mt-[5%] font-semibold`}>
        {string[userLanguage].partner}
      </div>
      <div className="w-full overflow-hidden mt-[3%] ">
        <div
          ref={scrollRef}
          className="flex flex-row"
          style={{
            width: `${array1.length * 10}%`, // 337px width + 20px gap
            transform: `translateX(-${scrollPosition}px)`,
          }}
        >
          {[...array1, ...array1].map((v: any, index: number) => {
            return (
              <img
                key={index}
                style={{
                  width: "15%",
                  backgroundColor: "white",
                  marginRight: 20,
                  borderRadius: 10,
                }}
                src={v}
              />
            );
          })}
        </div>
      </div>
      <div className="w-full overflow-hidden mt-[3%]">
        <div
          ref={scrollRef2}
          className="flex flex-row"
          style={{
            width: `${array2.length * 10}%`, // 337px width + 20px gap
            transform: `translateX(-${
              (scrollRef2?.current as HTMLDivElement)?.offsetWidth * 2 -
              450 -
              scorllPosition2
            }px)`,
          }}
        >
          {[...array2, ...array2].map((v: any, index: number) => {
            return (
              <img
                key={index}
                style={{
                  width: "15%",
                  backgroundColor: "white",
                  marginRight: 20,
                  borderRadius: 10,
                }}
                src={v}
              />
            );
          })}
        </div>
      </div>
      <div className=" absolute bottom-0 w-full flex items-center justify-center">
        <InqueryBanner
          body1Text={string[userLanguage].MainpartnerSectionBody1}
          inqueryText={string[userLanguage].MainpartnerSectionInqueryButton}
        />
      </div>
    </div>
  );
}
