import axios from "axios";
import React, { useEffect, useState } from "react";
import { LanguageProps } from "types/Type";
import string from "locales";
import Check from "asset/image/Check.png";
import CheckYellow from "asset/image/CheckYellow.png";
import { fontSize20, fontSize24, fontSize26, fontSize32 } from "const/fontSize";
import { useNavigate } from "react-router-dom";
///
export default function InqeryForm({ userLanguage }: LanguageProps) {
  const [companyName, setCompanyName] = useState("");
  const [number, setNumber] = useState<string>("");
  const [check, setCheck] = useState(false);
  const navigate = useNavigate();
  const submitHandler = async () => {
    let config = {
      method: "post",
      url: "/mpx_api/contact",
      headers: {
        "X-API-KEY": process.env.REACT_APP_MPX_API_KEY,
        "Content-Type": "application/json",
      },
      data: {
        name: companyName,
        contact: number,
        user_check: "Y",
      },
    };
    let config2 = {
      method: "post",
      url: "/express/slack",
      data: { text: `업체명 : ${companyName}\n전화번호 : ${number}` },
    };
    if (companyName && number && check) {
      try {
        // axios.request(config2).then(() => {});

        fetch("https://express.moneyplex.net/slack", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            text: `업체명 : ${companyName}\n전화번호 : ${number}`,
          }),
        }).then((v) => {
          console.log(v);
        });
      } catch (err) {
        console.warn(err);
      }
      axios.request(config).then(() => {
        alert(string[userLanguage].InquerySuccess);
        navigate("/");
      });
    }
  };
  return (
    <div className=" px-50 flex flex-col items-center justify-center sm:px-0">
      <div className=" sm:w-[40%]">
        <div className={`mt-[70px] ${fontSize20} mb-[10px]`}>
          {string[userLanguage].InqueryFormName}
          <span className=" text-redcolorFF">*</span>
        </div>
        <input
          value={companyName}
          placeholder={string[userLanguage].InqueryPlaceHolderName}
          onChange={(e) => {
            setCompanyName(e.target.value);
          }}
          className={` ${fontSize20} border border-solid border-borederA2 rounded-[10px]  w-full p-[20px] font-medium`}
        />
        <div className={`mt-40 ${fontSize20} mb-[10px]`}>
          {string[userLanguage].InqueryFormNumber}
          <span className=" text-redcolorFF">*</span>
        </div>
        <input
          inputMode="tel"
          placeholder={string[userLanguage].InqueryPlaceHolderNumber}
          value={number}
          onChange={(e) => {
            setNumber(e.target.value);
          }}
          className={` ${fontSize20} border border-solid border-borederA2 rounded-[10px]  w-full p-[20px] font-medium`}
        />
        <div className={`mt-40 ${fontSize20} mb-[10px]`}>
          {string[userLanguage].InqueryFormPrivate}
          <span className=" text-redcolorFF">*</span>
        </div>
        <div className="flex    border border-solid border-borederA2 rounded-10  px-20 h-[68px] font-medium">
          <div
            onClick={() => {
              setCheck((prev) => !prev);
            }}
            className={`cursor-pointer relative flex items-center  ${fontSize20} h-full gap-x-15 sm:w-[100%] text-borederA2 `}
          >
            {check ? (
              <img
                style={{
                  width: 34,
                }}
                src={CheckYellow}
              />
            ) : (
              <img
                style={{
                  width: 34,
                }}
                src={Check}
              />
            )}
            <span
              onClick={(e) => {
                e.stopPropagation();
                const openWindow = window.open("", "_blank")!;
                openWindow.location =
                  "https://moneyplex.net/terms/privacy_policy";
              }}
              className="relative top-2"
            >
              {string[userLanguage].InqueryCheckText}
            </span>
          </div>
        </div>
        <span
          className={`${fontSize20}  mt-12 inline-block font-medium text-borederA2 `}
        >
          {string[userLanguage].InqueryPrivateText}
        </span>
      </div>

      <button
        onClick={submitHandler}
        className={`${
          companyName && number && check
            ? "bg-primary"
            : "bg-[#D5D7D9] cursor-default"
        } min-w-300 mt-60 mb-150  p-10 w-full h-60   text-white font-bold rounded-10 sm:w-[20%] sm:h-94 sm:min-w-100 sm:text-30`}
      >
        {string[userLanguage].InqueryApply}
      </button>
    </div>
  );
}
