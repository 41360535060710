import { useEffect, useState } from "react";
import { LanguageCode } from "types/Type";
const useUserLanguage = (): [
  LanguageCode,
  React.Dispatch<React.SetStateAction<LanguageCode>>
] => {
  const [userLanguage, setUserLanguage] = useState<LanguageCode>("KR");
  useEffect(() => {
    const userLanguage = localStorage.getItem("lang");
    if (userLanguage === null) {
      const userLanguage = navigator.language.slice(0, 2);
      let selectLanguage;
      switch (userLanguage) {
        case "ko":
          selectLanguage = "KR";
          break;
        case "en":
          selectLanguage = "EN";
          break;
        case "ja":
          selectLanguage = "JP";
          break;
        case "zh":
          selectLanguage = "CN";
          break;
        case "th":
          selectLanguage = "TH";
          break;
        default:
          selectLanguage = "EN";
      }
      localStorage.setItem("lang", selectLanguage);
      setUserLanguage(selectLanguage as LanguageCode);
    } else {
      setUserLanguage(userLanguage as LanguageCode);
    }
  }, [userLanguage]);
  return [userLanguage, setUserLanguage];
};

export default useUserLanguage;
