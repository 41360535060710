/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import { GLOBLE_COLOR, IMG_SOURE } from "../../../const/const";
import AppScreen from "asset/image/appScreen.png";
import KioskScreen from "asset/image/kioskImg.png";
import { fontSize36, leading50 } from "const/fontSize";
import KioskImg from "asset/image/KioskImage5.png";
import Train from "asset/image/Train.png";
import Card from "asset/image/MobileCard.png";
import string from "locales";
import { LanguageCode, LanguageProps } from "types/Type";
import { SectionsContainer, Section } from "react-fullpage";
export default function AppKioskInfo({
  userLanguage,
  sectionRef,
}: {
  userLanguage: LanguageCode;
  sectionRef: any;
}) {
  return (
    <>
      <div
        ref={(el) => (sectionRef.current[1] = el)}
        className={`leading-50 h-[calc(100vh-75px)] flex relative bg-[#F8FcFF] w-full  pb-[17%] pt-[10%]`}
      >
        <div className="flex flex-col pl-[20%] mb-30">
          <div className={`text-32 text-primary font-bold mb-16`}>
            {string[userLanguage].MaincardSectionTitle}
          </div>
          <div className={`text-36 font-medium leading-45`}>
            {string[userLanguage].MaincardSectionBody1}
          </div>
          <div className={`text-36 font-medium leading-45`}>
            {string[userLanguage].MaincardSectionBody2}
          </div>
          <div className={`text-36 font-medium leading-45`}>
            {string[userLanguage].MaincardSectionBody3}
          </div>
          <div
            className={`text-36 font-medium mt-32 text-borederA2 leading-36`}
          >
            {string[userLanguage].MaincardSectionBody4}
          </div>
        </div>
        <img className=" absolute bottom-0 object-fill" src={Train} />

        <img
          className="z-1 absolute bottom-0 right-[20%]"
          width={"25%"}
          src={Card}
        />
      </div>

      <div
        ref={(el) => (sectionRef.current[2] = el)}
        className="h-[calc(100vh-75px)] flex justify-center my-50"
      >
        <div className=" relative flex justify-center items-center px-[20%] py-[1%]">
          <div
            className={`text-36 w-[80%] flex flex-col justify-center ${leading50}`}
          >
            <div className={` text-primary font-bold text-32 mb-16 leading-50`}>
              APP
            </div>
            <span className=" font-medium  leading-45 mb-32 tracking-[0.5px]">
              {string[userLanguage].MainappSectionTitle}
            </span>
            <span className="text-[#A2AAB4] inline-block  font-medium leading-50 tracking-[0.5px]">
              {string[userLanguage].MainappSectionSubBody}
            </span>
          </div>
          <div className="flex w-full mt-[8%] relative right-[-30px] items-end justify-end">
            <img
              style={{
                width: "95%",
              }}
              src={AppScreen}
            />
          </div>
        </div>
      </div>

      <div
        ref={(el) => (sectionRef.current[3] = el)}
        className="flex h-[calc(100vh-75px)] relative overflow-hidden justify-center text-right bg-[#F8FcFF] px-[20%] "
      >
        <div className="w-[50%] min-w-[519px]  relative left-[-30px] overflow-hidden  ">
          <img
            className=" object-fill 2xl:object-cover"
            style={{
              height: "100vh",
            }}
            src={KioskImg}
          />
        </div>
        <div
          className={`w-[50%]  text-36 pt-30  flex flex-col gap-y-[10px] justify-center  mb-30`}
        >
          <div className={` text-primary font-bold text-32 tracking-[0.5px] `}>
            KIOSK
          </div>
          <span style={{}} className=" font-medium tracking-[0.5px] leading-50">
            {string[userLanguage].MainkioskSectionTitle}
          </span>

          <span className=" text-[#A2AAB4] font-medium leading-50">
            {string[userLanguage].MainkioskSectionBody}
          </span>
        </div>
      </div>
    </>
  );
}
