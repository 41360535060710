/* eslint-disable jsx-a11y/alt-text */
import React, { ImgHTMLAttributes, useEffect, useRef, useState } from 'react';
import {
  Emart,
  Gkl,
  Gungi,
  Hyu,
  Incheonairport,
  Incheontourism,
  incheonportauthority,
  KbBank,
  Kbinvestment,
  Korea_tourism,
  Kpc,
  Kreao,
  Kstartup,
  Kvic,
  Kvic2,
  Newborn,
  SevenLuck,
  Thehyundai,
} from 'const/imageImport';
import Background from 'asset/image/Background.png';
import './patner.css';
import MessageIcon from 'asset/image/messageIcon.png';
import PluseButton from 'asset/image/PlusButton.png';
import MobileBackground from 'asset/image/MobileBackground.png';
import { LanguageProps } from 'types/Type';
import string from 'locales';
import InqueryBanner from 'component/common/InqueryBanner';
export default function MobilePartnerShip({ userLanguage }: LanguageProps) {
  const array1 = [
    Emart,
    Kbinvestment,
    KbBank,
    Korea_tourism,
    Incheontourism,
    incheonportauthority,
    Gkl,
    Kreao,
    Gungi,
  ];
  const array2 = [Incheonairport, Kpc, Newborn, Kvic, Kstartup, Kvic2, Hyu, Thehyundai, SevenLuck];
  const [scrollPosition, setScrollPosition] = useState(0);
  const [scorllPosition2, setScrollPosition2] = useState(0);
  const scrollRef = useRef<HTMLDivElement>(null);
  const scrollRef2 = useRef<HTMLDivElement>(null);
  const [isClick, setIsClick] = useState(false);

  useEffect(() => {}, [isClick]);
  return (
    <div className='flex flex-col items-center  bg-[#EFF2F4]'>
      <div className='text-16 mt-40 font-bold'>{string[userLanguage].partner}</div>
      <div className='flex flex-col justify-center items-center mt-15'>
        <div className=' flex flex-row flex-wrap gap-x-10 justify-center gap-y-10'>
          {array1.map((v) => {
            return (
              <img
                style={{
                  width: 'calc(100vw / 3 - 20px)',
                  height: 60,
                  objectFit: 'cover',
                }}
                src={v}
              />
            );
          })}
          {isClick &&
            array2.map((v) => {
              return (
                <img
                  style={{
                    width: 'calc(100vw / 3 - 20px)',
                    height: 60,
                  }}
                  src={v}
                />
              );
            })}{' '}
        </div>
        <button
          onClick={() => {
            setIsClick((prev: boolean) => !prev);
          }}
        >
          <img
            style={{
              transform: isClick ? 'rotate(180deg)' : '',
            }}
            className='my-20'
            src={PluseButton}
            width={40}
          />
        </button>
      </div>
      <InqueryBanner
        body1Text={string[userLanguage].MainpartnerSectionBody1}
        inqueryText={string[userLanguage].MainpartnerSectionInqueryButton}
      />
    </div>
  );
}
