import TabButton from "component/common/TabButton";
import { useEffect, useState } from "react";
import SearchDiv from "./component/SearchDiv";
import FaqList from "./component/FaqList";
import { FaqApp, FaqKiosk, FaqETC, FaqTotal } from "const/FaqList";
import { LanguageCode } from "types/Type";
import { useOutletContext } from "react-router-dom";
import string from "locales";
import axios from "axios";

export default function CustermerService() {
  const [currentTab, setCurrentTab] = useState("");
  const [searchWord, setSearchWord] = useState("");
  const [debouncedValue, setDebouncedValue] = useState(searchWord);
  const [announceList, setAnnounceList] = useState([]);
  const [faqList, setFaqList] = useState<any>(FaqTotal["KR"]);
  const { userLanguage } = useOutletContext<{
    userLanguage: LanguageCode;
  }>();
  const [faqCategory, setFaqCategory] = useState(string[userLanguage].CSTotal);

  useEffect(() => {
    let sendLanguage;
    switch (userLanguage) {
      case "CN":
        sendLanguage = "cn";
        break;
      case "CT":
        sendLanguage = "tw";
        break;
      case "EN":
        sendLanguage = "en";
        break;
      case "JP":
        sendLanguage = "jp";
        break;
      case "KR":
        sendLanguage = "kr";
        break;
      case "TH":
        sendLanguage = "th";
        break;
    }

    axios
      .request({
        method: "get",
        url: `/mpx_api/notice?per_page=1&rows=100&language=${sendLanguage}`,
        headers: {
          "X-API-KEY": process.env.REACT_APP_MPX_API_KEY,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        setAnnounceList(
          response.data.return_data.list.map(
            (v: { subject: string; content: string; wdate: string }) => {
              return {
                title: v.subject,
                body: v.content,
                date: v.wdate,
              };
            }
          )
        );
      })
      .catch((error) => {
        console.log(error);
      });
  }, [userLanguage]);

  useEffect(() => {
    setFaqList(() => {
      let faqList;
      switch (faqCategory) {
        case string[userLanguage].CSTotal:
          faqList = FaqTotal;
          break;
        case string[userLanguage].CSKiosk:
          faqList = FaqKiosk;
          break;

        case string[userLanguage].CSETC:
          faqList = FaqETC;
          break;

        case string[userLanguage].CSMoneyplex:
          faqList = FaqApp;
          break;
      }
      return faqList;
    });
  }, [faqCategory]);
  return (
    <div className="text-center mt-50 sm:mt-75 mb-100">
      <SearchDiv
        searchWord={searchWord}
        setSearchWord={setSearchWord}
        setFaqList={setFaqList}
        userLanguage={userLanguage}
        debouncedValue={debouncedValue}
        setDebouncedValue={setDebouncedValue}
      />
      <FaqList
        announceList={announceList}
        faqList={faqList}
        userLanguage={userLanguage}
        faqCategory={faqCategory}
        setFaqCategory={setFaqCategory}
        searchWord={searchWord}
        setSearchWord={setSearchWord}
        debouncedValue={debouncedValue}
        setDebouncedValue={setDebouncedValue}
      />
    </div>
  );
}
