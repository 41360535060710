import React from "react";
import { IMG_SOURE } from "../../../const/const";
import KioskImg from "asset/image/mainKioskImg.png";
import MainKioskLocation from "asset/image/mainLocationPin.png";
import {
  fontSize32,
  fontSize26,
  fontSize36,
  fontSize30,
  fontSize28,
} from "const/fontSize";
import string from "locales";
import { LanguageProps } from "types/Type";
import { Link } from "react-router-dom";
export default function MainKioskImg({ userLanguage }: LanguageProps) {
  return (
    <div>
      <div
        className="py-[10%] px-[20%] h-[calc(100vh-75px)]"
        style={{
          backgroundImage: `url(${KioskImg})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center 20%",
        }}
      >
        <div className="">
          <img src={MainKioskLocation} width={86} />
          <div>
            <div className={`${fontSize36} mt-37 font-medium  leading-50`}>
              {string[userLanguage].kioskSetup}
              <br />
              {string[userLanguage].kioskLoation}
            </div>
            <div
              style={{}}
              className={`mt-10 font-normal leading-50 ${fontSize28}`}
            >
              {string[userLanguage].mainKioskFind}
            </div>
            <Link to={"kiosklocation"}>
              <button
                className={`${fontSize32}  mt-55 py-25  bg-primary rounded-10  font-bold text-white 2xl:w-[443px] xl:w-[380px] lg:w-[320px] md:w-[255px] max-md:w-[255px]`}
              >
                {string[userLanguage].KioskFindDirect}
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
