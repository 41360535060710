import React from 'react';
import AppScreen from 'asset/image/appScreen.png';
import KioskScreen from 'asset/image/kioskImg.png';
import { fontSize36, leading50 } from 'const/fontSize';
import KioskImg from 'asset/image/KioskLeft.png';
import Train from 'asset/image/Train.png';
import Card from 'asset/image/MobileCard.png';
import string from 'locales';
import { LanguageCode, LanguageProps } from 'types/Type';
export default function MobileAppKiosk({ userLanguage }: LanguageProps) {
  return (
    <>
      <div className={`${leading50} flex relative bg-[#F8FcFF] w-full  pb-[17%] pt-[10%]`}>
        <div className='flex flex-col pl-[15%] mb-30 leading-25'>
          <div className={`text-16 text-primary  font-bold break-all`}>
            {string[userLanguage].MaincardSectionTitle}
          </div>
          <div className={`text-16 font-bold break-all`}>
            {string[userLanguage].MaincardSectionBody1}
          </div>
          <div className={`text-16  font-bold break-all`}>
            {string[userLanguage].MaincardSectionBody2}
          </div>
          <div className={`text-16  font-bold break-all`}>
            {string[userLanguage].MaincardSectionBody3}
          </div>
          <div className={`text-14 font-medium text-borederA2 break-all`}>
            {string[userLanguage].MaincardSectionBody4}
          </div>
        </div>
        <img className=' absolute bottom-0 object-fill' src={Train} />

        <img className='z-10 absolute bottom-0 right-100' width={'25%'} src={Card} />
      </div>

      <div className=' flex justify-center my-50 break-all'>
        <div className='flex flex-col justify-center items-center px-[15%] py-[1%]'>
          <div className={` w-full flex flex-col justify-center leading-23`}>
            <div className={`text-16 text-primary font-bold`}>APP</div>
            <span className='text-16 font-bold break-all mb-6'>
              {string[userLanguage].MainappSectionTitle}
            </span>
            <span className='text-14 text-[#A2AAB4] inline-block  font-medium break-all '>
              {string[userLanguage].MainappSectionSubBody}
            </span>
          </div>
          <div className='flex w-full mt-[8%] items-end justify-end'>
            <img
              style={{
                width: '50%',
              }}
              src={AppScreen}
            />
          </div>
        </div>
      </div>
      <div className='flex justify-center text-right bg-[#F8FcFF] px-24 break-all'>
        <div className='w-full flex items-center justify-center'>
          <img
            className=''
            style={{
              objectFit: 'fill',
              height: '100%',
              maxHeight: 400,
            }}
            src={KioskImg}
          />
        </div>
        <div className={`w-full pt-30  flex flex-col gap-y-[10px] justify-center leading-23 mb-30`}>
          <div className={` text-16 text-primary font-bold `}>KIOSK</div>
          <span style={{}} className=' text-16 font-bold break-all'>
            {string[userLanguage].MainkioskSectionTitle}
          </span>

          <span className=' text-14 text-[#A2AAB4] break-all'>
            {string[userLanguage].MainkioskSectionBody}
          </span>
        </div>
      </div>
    </>
  );
}
