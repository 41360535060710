import TabButton from 'component/common/TabButton';
import string from 'locales';
import React, { useEffect, useState } from 'react';
import { Link, useOutletContext, useRouteLoaderData } from 'react-router-dom';
import { LanguageCode } from 'types/Type';
import EventImg from 'asset/image/eventExample.png';
import DumeImg from 'asset/image/Dume.png';
import VKIMG_KR from 'asset/image/vk_KR.png';
import VKIMG_EN from 'asset/image/vk_EN.png';
import VKIMG_CN from 'asset/image/vk_CN.png';
import VKIMG_CT from 'asset/image/vk_CT.png';
import VKIMG_TH from 'asset/image/vk_TH.png';
import VKIMG_JP from 'asset/image/vk_JP.png';

export default function EventPage() {
  const { userLanguage: language } = useOutletContext<{
    userLanguage: LanguageCode;
  }>();
  const [currentTab, setCurrentTab] = useState(string[language].event);

  useEffect(() => {
    setCurrentTab(string[language].event);
  }, [language]);
  const selectImg = () => {
    if (language === 'KR') return VKIMG_KR;
    if (language === 'EN') return VKIMG_EN;
    if (language === 'CN') return VKIMG_CN;
    if (language === 'CT') return VKIMG_CT;
    if (language === 'JP') return VKIMG_JP;
    if (language === 'TH') return VKIMG_TH;
  };
  return (
    <div className='sm:min-h-[500px] flex flex-col items-center justify-center '>
      <div className='w-full pt-93 mb-30  px-30 sm:mb-50 '>
        <TabButton
          leftText={string[language].event}
          rightText={string[language].closeEvent}
          currentTab={currentTab}
          setCurrentTab={setCurrentTab}
        />
      </div>

      {currentTab === string[language].event ? (
        <div className='grid grid-cols-2 mt-30 mb-100  sm:mb-100 sm:my-10 gap-y-30 px-30 text-center sm:flex flex-row gap-x-30'>
          <Link to={'detail'}>
            <div>
              <img className='h-[200px] sm:h-[300px]' style={{}} src={selectImg()}></img>
              <div className='mt-10 sm:mt-15 text-12 sm:text-20 text-[#373F57] '>
                2024.07.01 ~ 2024.12.31
              </div>
            </div>
          </Link>
          <div>
            <img className='h-[200px] sm:h-[300px]' style={{}} src={DumeImg}></img>
          </div>
          <div>
            <img className='h-[200px] sm:h-[300px]' style={{}} src={DumeImg}></img>
          </div>
        </div>
      ) : (
        <div className='grid grid-cols-2 mt-30 mb-100 sm:mb-100 sm:my-10 gap-y-30 px-30 text-center sm:flex flex-row gap-x-30'>
          <div>
            <img className='h-[200px] sm:h-[300px]' src={DumeImg}></img>
          </div>
          <div>
            <img className='h-[200px] sm:h-[300px]' src={DumeImg}></img>
          </div>
          <div>
            <img className='h-[200px] sm:h-[300px]' src={DumeImg}></img>
          </div>
        </div>
      )}
    </div>
  );
}
