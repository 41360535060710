import React, { useState } from "react";

import string from "locales";

export default function KioskList({
  isLoading,
  kioskData,
  currentLanguage,
  userLanguage,
  setCurrentTab,
  setSelectedMarker,
  windowSize,
}: {
  isLoading: boolean;
  kioskData: any;
  currentLanguage: string;
  userLanguage: string;
  setCurrentTab: any;
  setSelectedMarker: any;
  windowSize: { width: number };
}) {
  const [selectCategory, setSelectCategory] = useState(
    string[userLanguage as "EN"]["서울"]
  );

  return isLoading ? (
    <></>
  ) : (
    <div className="px-40">
      <div className="flex flex-row py-40 text-18 sm:text-24 justify-center sm:py-58 ">
        {[
          string[userLanguage as "EN"]["서울"],
          string[userLanguage as "EN"]["경기"],
          string[userLanguage as "EN"]["인천"],
          string[userLanguage as "EN"]["부산"],
        ].map((v, i) => {
          return (
            <div
              onClick={() => {
                setSelectCategory(v);
              }}
              className=" relative flex cursor-pointer flex-col items-center justify-center mr-20 "
            >
              <div
                className={`${
                  selectCategory === v ? "text-primary" : "text-[#A2AAB4]"
                } `}
              >
                {v}
              </div>
              {selectCategory === v && (
                <div
                  className={` absolute top-25 sm:top-32  w-56 h-2 bg-primary`}
                ></div>
              )}
            </div>
          );
        })}
      </div>
      <div className="flex flex-col justify-center w-full items-center mb-50">
        <div className=" justify-center xl:grid-cols-3 lg:grid-cols-2 sm:grid">
          {kioskData[selectCategory]?.map(
            (v: {
              kiosk_name: string;
              kiosk_name_en: string;
              kiosk_install_place_en: string;
              kiosk_address_en: string;
              kiosk_img_url: string;
              kiosk_install_place: string;
              kiosk_op_time: string;
            }) => {
              return (
                <div className="break-all mb-50 max-w-[380px] sm:mr-30 sm:mb-125 ">
                  <div>
                    <img
                      className=" w-full h-[240px] rounded-[10px] "
                      style={{
                        objectFit: "cover",
                      }}
                      src={v.kiosk_img_url}
                    />
                    <div className=" text-16 sm:text-[28px] sm:min-h-50 mt-16 font-bold ">
                      {`${v[`kiosk_name${currentLanguage as "_en"}`]}`}
                    </div>
                    <div className="break-all sm:min-h-70 text-16 sm:text-[24px] mt-2 leading-33 line-clamp-2">
                      {`${v[`kiosk_address${currentLanguage as "_en"}`]}`}
                    </div>
                    <div className="mt-[10px] flex flex-row  justify-between items-center ">
                      <div>
                        <span className=" text-16 sm:text-[26px] font-medium text-primary ">
                          {string[userLanguage as "EN"].MapOpenTime}
                        </span>
                        <span className="text-16 sm:text-[26px] font-medium ml-[8px]">
                          {v.kiosk_op_time}
                        </span>
                      </div>
                      {windowSize.width > 800 && (
                        <button
                          onClick={() => {
                            setCurrentTab(string[userLanguage as "EN"].MapMap);
                            setSelectedMarker(v);
                            window.scrollTo(0, 200);
                          }}
                          className=" w-[25%] border-primary border p-[10px] rounded-[10px] text-[26px] text-primary"
                        >
                          {string[userLanguage as "EN"].MapMapButton}
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              );
            }
          )}
        </div>
      </div>
    </div>
  );
}
