export const MainPageKO = {
  MaintitleBody1: "선불 교통카드와 함께하는",
  MaintitleBody2: "외화 환전 머니플렉스!",
  MaintitleSubBody: " 스마트하고 간편한 환전 서비스를 지금 만나보세요",
  MaincardSectionTitle: "선불 교통카드 발급",
  MaincardSectionBody1: "머니플렉스에서 발급받고,",
  MaincardSectionBody2: "대중교통부터 쇼핑까지,",
  MaincardSectionBody3: "어디서나 쉽고 간편한 결제",
  MaincardSectionBody4: "내외국인 모두 이용가능",
  MainappSectionTitle: "빠르고 간편한 환전예약 과 다양한 제휴처 쿠폰 혜택까지!",
  MainappSectionSubBody: "환전 후 남은 잔돈까지 관리해주는 머니플렉스",
  MainkioskSectionTitle: "선불 교통 카드 발급과\n충전 가능한 키오스크",
  MainkioskSectionBody:
    "100권종 통화,6개 국어를 지원하며 선불 교통 카드 발급과 충전, 면세점 및 백화점, 호텔, 멤버십 카드까지 지원하는 멀티 키오스크",
  MainpartnerSectionTitle: "파트너사",
  MainpartnerSectionBody1:
    '"외국인 고객을 위한 선불 교통카드 뿐만아니라,무인카드발급 지원과 운영 부담 없이 비즈니스 고객 기반을 넓힐 수 있는 국내 유일한 솔루션입니다." ',
  MainpartnerSectionBody2: '"',
  MainpartnerSectionInqueryButton: "도입 문의하기",
  MainmobileTilte1: "환전의 새로운 기준, 머니플렉스!",
  MainmobileTilte2: "현장에서 직접 확인하세요.",
};

export const MoneyPlexKO = {
  MoneyplexTabLeft: "머니플렉스 고객",
  MoneyplexTabRight: "비즈니스 문의",
  MoneyplexKioskTitle:
    "아직도 환전하는데 이곳저곳에서 찾아다니고 계시나요? 이제 머니플렉스 환전 키오스크를 만나보세요",
  MoneyplexExchangeProcess: "환전 프로세스",
  MoneyplexExchange1Title: "1단계 : 환전할 외화 선택하기",
  MoneyplexExchange1Body:
    " 다양한 외화를 지원하며, 미국 달러(USD), 유로(EUR), 일본 엔화(JPY),중국 위안화(CNY) 등 주요 15개국 통화를 선택할 수있습니다.",

  MoneyplexExchange2Title: "2단계 : 신분증(여권) 스캔하기",
  MoneyplexExchange2Body:
    "신분증(여권) 정보는 필수로 확인되며, 이는 한국에서 외화 환전을 위한 기본 절차입니다.",
  MoneyplexExchange3Title: "3단계 : 외화 지폐 투입하기",
  MoneyplexExchange3Body:
    "이제 선택한 외화를 키오스크에 투입할 차례입니다. 투입된 지폐는 키오스크에서 자동으로 인식되며, 환전 금액이 계산됩니다.",
  MoneyplexExchange4Title: "4단계 : 원화 수령하기",
  MoneyplexExchange4Body:
    "환전이 완료되면, 키오스크에서 원화를 수령할 수 있습니다. 환전된 금액은 바로 현금으로 지급됩니다. 환전이 완료 되면, 영수증이 출력됩니다. ",
  MoneyplexAppTitle:
    "MoneyPlex APP은 환전부터 포인트 결제까지 다양한 기능을 제공합니다. 모바일 앱 하나로 언제 어디서나 쉽게 환전을 이용하세요.",

  MoneyplexApp1Title: "키오스크 위치 검색",
  MoneyplexApp1Body:
    " MoneyPlex 앱을 통해 가까운 환전 키오스크 위치를 실시간으로 확인할 수 있습니다. 주변 키오스크를 찾아 빠르게 환전할 수 있도록 도와드립니다.",

  MoneyplexApp2Title: "MoneyPlex 멤버십",
  MoneyplexApp2Body:
    "MoneyPlex APP 사용자는 간단한 멤버십 인증을 통해 다양한 혜택을 누릴 수 있습니다. 멤버십 인증을 완료하면, 포인트 적립 및 환전예약 시 추가 할인 혜택을 받을 수 있습니다. (Only For Korean)",
  MoneyplexApp3Title: "MoneyPlex 환전 예약 (Only For Korean)",
  MoneyplexApp3Body:
    "내국인을 대상으로 한 MoneyPlex 앱의 환전 예약 기능을 통해 저렴한 환율로 외화환전을 사전에 예약할 수 있습니다. 원하는 환율 시점에 예약하여 편리하게 환전을 완료하세요.",
  MoneyplexApp4Title: "포인트샵 쿠폰 (Only For Korean)",
  MoneyplexApp4Body:
    "MoneyPlex멤버를 위한 포인트샵 쿠폰 구매 기능을 통해 적립된 포인트로 다양한 제휴사 쿠폰을 구매할 수 있습니다. 환전과 함께 포인트를 쌓아 두고, 다양한 혜택을 즐기세요. ",
  MoneyplexBusinessTitle: "국내 최고의 무인환전 키오스크 솔루션",
  MoneyplexBusinessBody:
    " 24시간 운영, 비용 절감, 고객 만족도 향상 등, 파트너사의 성공을 돕는 최적의 솔루션을 제공합니다.",
  MoneyplexFindKiosk: "키오스크 위치 찾기",
  MoneyplexBenefitTitle1: "24시간 운영 가능",
  MoneyplexBenefitBody1:
    '"고객들이 시간에 상관없이 환전할 수 있어, 서비스 제공 시간을 연장하고 고객 만족도를 극대화할 수 있습니다."',
  MoneyplexBenefitTitle2: "선불 교통카드&제휴처 카드지원",
  MoneyplexBenefitBody2:
    '"선불 교통카드 뿐만아닌 회원 전용카드도 발급이 가능해서 고객들이 선택의 폭이 넓어집니다.”',
  MoneyplexBenefitTitle3: "고객 만족도 증가",
  MoneyplexBenefitBody3:
    '"특히 외국인 관광객이 많은 호텔, 카지노, 대형마트와 같은 장소에서는 키오스크를 통해 고객 편의성을 극대화할 수 있습니다.”',
  MoneyplexBenefitTitle4: "간편한 설치 및 유지보수",
  MoneyplexBenefitBody4:
    '"설치가 간편하고, 유지보수도 최소화되어 파트너사가 별도의 복잡한 관리 절차 없이 쉽게 운영할 수 있습니다.”',
  MoneyplexBenefitTitle5: "수익 창출 기회",
  MoneyplexBenefitBody5:
    '"키오스크를 설치하면 기기 수익뿐만 아니라, 고객 유입 증가로 인한 추가 수익 창출도 기대할 수 있습니다.”',
  MoneyplexPartnerBenefitHeader: "파트너 혜택",
  MoneyplexPartnerBenefitHeader2:
    "머니플렉스 키오스크 설치 현황 및 파트너 혜택",
  MoneyplexPartnerBenefitTitle1: "고객 만족도 향상",
  MoneyplexPartnerBenefitBody1: "24시간 환전 서비스 제공",
  MoneyplexPartnerBenefitTitle2: "비용절감",
  MoneyplexPartnerBenefitBody2: "인권비 0원, 자동화된 운영 시스템",
  MoneyplexPartnerBenefitTitle3: "추가 수익 창출",
  MoneyplexPartnerBenefitBody3: "고객 유입 증가로 추가 매출",
  MoneyplexPartnerBenefitTitle4: "간편한 관리",
  MoneyplexPartnerBenefitBody4: "운영 관리 시스템으로 실시간 장애 모니터링",
  MoneyplexKioskExampleTitle: "키오스크 설치 사례",
  MoneyplexKioskExamplelocation1: "호텔 • 카지노",
  MoneyplexKioskExamplebody1: "서울 주요 호텔 및 카지노 설치",
  MoneyplexKioskExamplelocation2: "항만 • 철도역",
  MoneyplexKioskExamplebody2: " 크루즈항,서울역등 설치",
  MoneyplexKioskExamplelocation3: "백화점 • 대형마트",
  MoneyplexKioskExamplebody3: "전국 대형마트 23곳에 설치",
  MoneyplexCustmerReview: "고객후기",
  MoneyplexCustmerTitle: "실제 사용자 후기 및 성공 사례",
};
export const GuidePageKO = {
  GuideKioskBill: "지원통화",
  GuideKioskCurrencyExchange: "외화 환전",
  GuideKioskPoint: "포인트충전",
  GuideKioskPointSub: "앱적립/바코드적립",
  GuideKioskCardIssue: "선불카드 발급 및 충전",
  GuideKioskRecive: "수령방법",

  GuideAppReservation: "환전예약",
  GuideAppSignUp: "회원가입",
  GuideAppCoupon: "쿠폰 등록",
  GuideDetailAppConponText1: "01 하단 쿠폰탭",
  GuideDetailAppConponText2: "02 쿠폰 코드 입력",
  GuideDetailAppConponText3: "03 쿠폰 등록 완료",
  GuideDetailAppConponText4: "04 쿠폰 사용 가능",
  GuideDetailAppReservation1: "01 환전예약",
  GuideDetailAppReservation2: "02 지점선택",
  GuideDetailAppReservation3: "03 환전날짜선택",
  GuideDetailAppReservation4: "04 금액선택,통화선택",
  GuideDetailAppReservation5: "05 환전 예약신청",
  GuideDetailAppReservation6: "05 환전 예약신청 완료",
  GuideDetailAppReservation7: "06 입금대기",
  GuideDetailAppReservation8: "07 입금완료",
  GuideDetailAppReservation9: "08 키오스크 수령",

  GuideDetailKioskBill1: "01 여권 스캔",
  GuideDetailKioskBill2: "02 지원 통화",
  GuideDetailKioskCurrencyExchange1: "01 여권 또는 신분증 스캔",
  GuideDetailKioskCurrencyExchange2: "02 외화선택",
  GuideDetailKioskCurrencyExchange3: "03 지폐투입",
  GuideDetailKioskCurrencyExchange4: "04 원화 수령",
  GuideDetailKioskPointSubTitle1: "앱적립 - 머니플렉스 모바일 충전시",
  GuideDetailKioskPointSubTitle2: "KB 국민지갑 충전시",
  GuideDetailKioskPointSubTitle3: "잔동수령",
  GuideDetailKioskPointSubTitle4: "기부하기",
  GuideDetailKioskPointApp1: "모바일 충전 선택",
  GuideDetailKioskPointApp2: "회원선택",
  GuideDetailKioskPointApp3: "회원 - 바코드",
  GuideDetailKioskPointApp4: "비회원 - 번호입력",
  GuideDetailKioskPointApp5: "비회원 - 번호입력",
  GuideDetailKioskPointApp6: "충전완료",
  GuideDetailKioskKb1: "국민지갑 선택",
  GuideDetailKioskKb2: "동의하기",
  GuideDetailKioskKb3: "KB국민지갑",
  GuideDetailKioskKb4: "국민지갑 선택",
  GuideDetailKioskKb5: "바코드 태깅",
  GuideDetailKioskKb6: "충전완료",

  GuideDetailKioskCoin1: "원화수령 선택",
  GuideDetailKioskCoin2: "잔돈수령 선택",
  GuideDetailKioskCoin3: "회원 선택",
  GuideDetailKioskCoin4: "회원 - 바코드",
  GuideDetailKioskCoin5: "비회원 - 번호입력",
  GuideDetailKioskCoin6: "비회원 - 번호입력",
  GuideDetailKioskCoin7: "충전완료",
  GuideDetailKioskReturn1: "원화수령 선택",
  GuideDetailKioskReturn2: "기부하기 선택",
  GuideDetailKioskReturn3: "기부 완료",
  GuideDetailCardInfo:
    "선불카드는 klook 에서 사전 신청해서 발급 받기가 가능합니다.",
  GuideDetailSignUp1: "01 회원가입",
  GuideDetailSignUp2: "02 약관동의",
  GuideDetailSignUp3: "03 이메일인증",
  GuideDetailSignUp4: "04 비밀번호 설정",
  GuideDetailSignUp5: "05 보안 비밀번호 설정",
  GuideDetailSignUp6: "06 회원가입완료",

  GuideDetailReceiveMoneyTitle1: "원화수령",
  GuideDetailReciveMoneyTitle2: "머니플렉스 앱 충전",
  GuideDetailReceiveMoneySubTitle1: "잔돈수령",
  GuideDetailReceiveMoney1_1: "01 원화수령 선택",
  GuideDetailReceiveMoney1_2: "02 잔돈수령 방법 선택",
  GuideDetailReceiveMoney1_3: "03 회원/비회원선택",
  GuideDetailReceiveMoney1_4: "04 번호입력/바코드스캔",
  GuideDetailReceiveMoney1_5: "05 원화수령",
  GuideDetailReceiveMoney1_6: "06 완료",

  GuideDetailReceiveMoneySubTitle2: "기부(잔돈)",
  GuideDetailReceiveMoney2_1: "01 원화수령 선택",
  GuideDetailReceiveMoney2_2: "02 잔돈수령 선택",
  GuideDetailReceiveMoney2_3: "03 기부하기 선택",
  GuideDetailReceiveMoney2_4: "04 완료",

  GuideDetailReceiveMoneySubTitle3: "머니플렉스 앱 충전",
  GuideDetailReceiveMoney3_1: "01 교통카드 충전",
  GuideDetailReceiveMoney3_2: "02 회원 또는 비회원 선택",
  GuideDetailReceiveMoney3_3: "03 비회원 - 번호입력",
  GuideDetailReceiveMoney3_4: "04 회원 - 바코드 입력",
  GuideDetailReceiveMoney3_5: "05 완료 ",

  GuideDetailReceiveMoneySubTitle4: "KB국민지갑 충전",
  GuideDetailReceiveMoney4_1: "01 KB국민지갑 선택",
  GuideDetailReceiveMoney4_2: "02 개인정보 동의",
  GuideDetailReceiveMoney4_3: "03 바코드 스캔",
  GuideDetailReceiveMoney4_4: "04 충전 완료",

  GuideDetailReceiveMoneySubTitle5: "기부하기 (전체금액 기부하기)",
  GuideDetailReceiveMoney5_1: "01 기부하기 선택",
  GuideDetailReceiveMoney5_2: "02 기부완료",
  GuideToolTip1: "하단 쿠폰 탭에서 등록 하세요.\n(외국인 대상)",
  GuideToolTip2: "로그인시 회원 비밀번호 설정이 필요합니다.",
  GuideToolTip3: `보안 비밀번호 6자리 설정이 필요합니다.\n(앱 설행 및 포인트 사용시)`,
  GuideToolTip4: "3 영업일 이후 날짜부터 선택가능",
  GuideToolTip5:
    "환전 금액을 계좌로 입금하셔야 진행 합니다.\n당일 24시 전 까지 입금만 유효합니다.\n(환율이 변동되기 때문에 24시가 지나면 취소됩니다)",
  GuideToolTip6:
    "입금완료 후 취소나 환불 요청시, 1%의 수수료가 차감된 금액이 환불 됩니다.",
  GuideToolTip7: "회원일경우-바코드 스캔",
  GuideToolTip8: "비회원일경우-휴대폰번호입력",

  GotoKlook: "klook 바로 가기",
  HighlightText: "당일 24시",
  GuideBill: "지폐",
  GuideCoin: "동전",
  GuideOldBank: "구권",
  GuideNewBank: "신권",
  GuideSubText:
    "1000원 미만은 원화 수령시 머니플렉스 충전 또는 기부하기만 가능합니다.",
};

export const MapPageKO = {
  서울: "서울",
  부산: "부산",
  경기: "경기",
  인천: "인천",
  MapList: "리스트로 보기",
  MapMap: "지도로 보기",
  MapOpenTime: "영업 시간",
  MapMapButton: "지도",
  MapLength: "개 지점",
  MapForiegn: "외화",
};

export const InqeryPageKO = {
  InqueryTitle1: "머니플렉스 도입문의를",
  InqueryTitle2: "간편하게 문의해보세요!",
  InqueryFormName: "업체명",
  InqueryFormNumber: "연락처",
  InqueryFormPrivate: "개인정보 수집동의",
  InqueryPlaceHolderName: "업체명을 입력해주세요",
  InqueryPlaceHolderNumber: "연락처를 입력해주세요",
  InqueryPrivateText:
    "입력하신 정보는 동일한 범위를 벗어난 목적으로 사용되지 않습니다.",
  InqueryApply: "신청하기",
  CSTitle: "무엇을 도와드릴까요?",
  CSPlaceHolder: "궁금한점이 있다면 키워드를 검색해보세요.",
  InqueryCheckText: "개인 정보 수집 동의 (필수)",
  CSFAQ: "자주 묻는 질문",
  CsAnnouncements: "공지사항",
  CSTotal: "전체",
  CSKiosk: "키오스크",
  CSMoneyplex: "머니플렉스 앱",
  CSETC: "기타",
  InquerySuccess: "문의가 정상적으로 전송되었습니다.",
  CSNotFound: "검색 결과가 없습니다.",
  AnnounceNotFound: "공지사항이 없습니다.",
};
