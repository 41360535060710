import TabButton from "component/common/TabButton";
import React, { useEffect, useState } from "react";
import Moneyplex1 from "asset/image/Moneyplex_1.png";
import KioskImg from "asset/image/kioskImg.png";
import AppStore from "asset/image/appstoreButton.png";
import PlayStore from "asset/image/googlePlayButton.png";
import KioskInfo from "./component/KioskInfo";
import AppInfo from "./component/AppInfo";
import B2BInfo from "./component/B2BInfo";
import B2BInfo2 from "./component/B2BInfo2";
import B2BInfo3 from "./component/B2BInfo3";
import LeftIcon from "asset/image/LeftIcon.png";
import RightIcon from "asset/image/RightIcon.png";
import string from "locales";
import useWindowSizeCustom from "hooks/useWindowSizeCustom";
import {
  fontSize32,
  fontSize26,
  fontSize34,
  fontSize40,
  fontSize24,
  fontSize30,
  fontSize36,
} from "const/fontSize";
import useUserLanguage from "hooks/useUserLanguage";
import { Link, useOutletContext } from "react-router-dom";
import { LanguageCode } from "types/Type";
import { personObject } from "const/const";
const personArray = [
  {
    person: "32세, 직장인",
    content:
      '"출장 때문에 외화가 필요할 때가 많은데, 은행 영업시간에 맞추기 어려웠어요. 덕분에 언제든지 환전할 수 있어서 정말 편리 했습니다.”',
  },
  {
    person: "29세, 여행객",
    content:
      '"해외여행 전에 환전을 깜빡하고 못 했는데, 공항에서 머니플렉스 키오스크를 발견하고 바로 환전할 수 있었어요. 은행보다 수수료도 저렴해요!"',
  },
  {
    person: "45세, 카지노 매니저",
    content:
      ' "우리 카지노에 머니플렉스 키오스크 설치 이후로 고객들의 환전 불편함이 크게 줄었어요. 24시간 운영이 가능하니 고객들의 만족도가 확실히 높아졌습니다."',
  },
  {
    person: "26, 대학생",
    content:
      '"친구들과 일본 여행을 가기 전에 환율이 제일 좋을 때 머니플렉스 앱으로 환전했어요. 환율 비교도 되고, 모바일 앱으로 간편하게 해결할 수 있었던 점이 좋았어요"',
  },
];
export default function MoneyPlex() {
  const { userLanguage: language } = useOutletContext<{
    userLanguage: LanguageCode;
  }>();
  const [startNumber, setStartNumber] = useState(personArray.length);
  const [endNumber, setEndNumber] = useState(personArray.length + 2);
  const [currentTab, setCurrentTab] = useState(
    string[language].MoneyplexTabRight
  );
  const windowSize = useWindowSizeCustom();

  useEffect(() => {
    setCurrentTab(string[language].MoneyplexTabRight);
  }, [language]);

  return (
    <div className="max-w-[2000px] mx-auto">
      <div className="pt-93 px-25 ">
        <TabButton
          leftText={string[language].MoneyplexTabLeft}
          rightText={string[language].MoneyplexTabRight}
          currentTab={currentTab}
          setCurrentTab={setCurrentTab}
        />
      </div>

      {currentTab === string[language].MoneyplexTabLeft ? (
        <>
          <div className="mt-20 sm:mt-90 flex flex-col mb-30 sm:mb-100 lg:px-[20%] px-25">
            <KioskInfo userLanguage={language} />
          </div>
          <div className="w-full py-[8%] sm:py-[10%] bg-primary flex  items-center justify-center gap-x-10 md:py-[3%] md:px-15 ">
            <Link to={"https://moneyplex.page.link/main"} target="_blank">
              <img className="w-110 sm:w-[100%] sm:w-auto" src={AppStore}></img>
            </Link>
            <Link to={"https://moneyplex.page.link/main"} target="_blank">
              <img
                className="w-110 sm:w-[100%] sm:w-auto"
                src={PlayStore}
              ></img>
            </Link>
          </div>
          <AppInfo userLanguage={language} />
          {windowSize.width > 1100 ? (
            <div className="flex justify-between relative  px-[20%] items-center mb-50">
              <div
                onClick={() => {
                  if (startNumber === 0) {
                    setStartNumber(personArray.length - 1);
                    setEndNumber(personArray.length + 1);
                  } else {
                    setStartNumber((prev) => prev - 1);
                    setEndNumber((prev) => prev - 1);
                  }
                }}
                className="min-w-50 max-w-100 relative top-100 right-30"
              >
                <img src={LeftIcon} />
              </div>
              <div className="my-77 flex flex-col">
                <div className={`${fontSize36} font-bold text-primary mb-34 `}>
                  {string[language].MoneyplexCustmerReview}
                </div>
                <div className={`${fontSize40} mb-90 font-bold `}>
                  {string[language].MoneyplexCustmerTitle}
                </div>
                <div className="flex gap-x-30 ">
                  {[
                    ...personObject[language],
                    ...personObject[language],
                    ...personObject[language],
                  ]
                    .slice(startNumber, endNumber + 1)
                    .map((v) => {
                      return (
                        <div className="w-[340px] bg-[#F4F7FB] rounded-30 min-h-[330px] p-20 ">
                          <div className={`${fontSize30} font-bold mb-9 `}>
                            {v.person}
                          </div>
                          <div
                            className={`${fontSize24} leading-35 text-[#696D72]`}
                          >
                            {v.content}
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
              <div
                onClick={() => {
                  if (startNumber === 9) {
                    setStartNumber(personArray.length + 2);
                    setEndNumber(personArray.length + 4);
                  } else {
                    setStartNumber((prev) => prev + 1);
                    setEndNumber((prev) => prev + 1);
                  }
                }}
                className="min-w-50 max-w-100 relative top-100 left-30"
              >
                <img src={RightIcon} />
              </div>
            </div>
          ) : (
            <div className="my-30 mb-60 sm:my-77 flex flex-col px-30  ">
              <div
                className={`font-bold text-16 ${fontSize34} text-primary mb-10 sm:mb-34`}
              >
                {string[language].MoneyplexCustmerReview}
              </div>
              <div
                className={`${fontSize40} text-16 mb-30 font-medium sm:font-bold`}
              >
                {string[language].MoneyplexCustmerTitle}
              </div>
              <div className="flex gap-y-15 flex-col">
                {personObject[language].map((v) => {
                  return (
                    <div className=" bg-[#F4F7FB] rounded-10 sm:rounded-30 p-20">
                      <div className={`${fontSize32} font-bold mb-9`}>
                        {v.person}
                      </div>
                      <div
                        className={`${fontSize26} text-[#696D72] leading-20 sm:leading-35 `}
                      >
                        {v.content}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </>
      ) : (
        <div className="">
          <B2BInfo userLanguage={language} />
          <B2BInfo3 userLanguage={language} />
        </div>
      )}
    </div>
  );
}
