import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import Logo from "asset/image/logo.png";
import White from "asset/image/worldWhite.png";
import World from "asset/image/world.png";
import { useLocation } from "react-router-dom";
import MobileLogo from "asset/image/mobileLogo.png";
import MobileBar from "asset/image/mobile_bar.png";
import MobileLan from "asset/image/mobile_lan.png";
import MobileBarBlack from "asset/image/MobileBarBlack.png";
import MobileLanBlack from "asset/image/MobileLanBlack.png";
import useWindowSizeCustom from "hooks/useWindowSizeCustom";
import string from "locales";
import { GLOBLE_COLOR } from "const/const";
import { LanguageCode } from "types/Type";
import CloseButton from "asset/image/plus.png";
import { Header as FullHeader } from "react-fullpage";
import "./Header.css";
export default function Header({
  userLanguage,
  setUserLanguage,
}: {
  userLanguage: LanguageCode;
  setUserLanguage: any;
}) {
  const [isTransrateHeader, setIsTransrateHeader] = useState(false);
  const windowSize = useWindowSizeCustom();
  const location = useLocation();
  const mobile = windowSize.width < 900 ? true : false;
  const [clickMenu, setClickMenu] = useState(false);
  const [preScrollY, setPreScrollY] = useState(-1);
  const [currentPage, setCurrentPage] = useState("");
  const [webClickMenu, setWebClickMenu] = useState(false);
  const [mobileClickMenu, setMobileClickMenu] = useState(false);
  const [currentLanguage, setCurrentLanguage] = useState(userLanguage);
  const [isHover, setIsHover] = useState(false);
  const preventScroll = () => {
    document.body.style.position = "fixed";
    document.body.style.width = "100%";
    document.body.style.overflowY = "scroll";
  };

  const allowScroll = () => {
    document.body.style.position = "";
    document.body.style.width = "";
    document.body.style.top = "";
    document.body.style.overflowY = "";
    window.scrollTo(0, preScrollY);
  };

  useEffect(() => {
    if (clickMenu) {
      preventScroll();
    }
    return () => {
      allowScroll();
    };
  }, [clickMenu]);
  useEffect(() => {
    setCurrentLanguage(userLanguage);
  }, [userLanguage]);
  useEffect(() => {
    window.scrollTo(0, 0);
    setCurrentPage(location.pathname.split("/")[1]);
    if (location.pathname !== "/") {
      setIsTransrateHeader(true);
      return;
    } else {
      setIsTransrateHeader(false);
    }

    const scrollHandler = () => {
      setPreScrollY(window.scrollY);
      if (window.scrollY > 5) {
        setIsTransrateHeader(true);
      } else {
        setIsTransrateHeader(false);
      }
    };
    window.addEventListener("scroll", scrollHandler);
    return () => {
      window.removeEventListener("scroll", scrollHandler);
    };
  }, [location]);
  const ImageRender = () => {
    if (clickMenu) return CloseButton;
    else return isTransrateHeader || clickMenu ? MobileBarBlack : MobileBar;
  };

  const modalRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    console.log(isHover);
  }, [isHover]);
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        modalRef.current &&
        !modalRef.current.contains(event.target as Node)
      ) {
        setWebClickMenu(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const renderHeaderbackground = () => {
    if (isHover && !isTransrateHeader) {
      return "linear-gradient(180deg,rgba(0, 0, 0, 0.06) -88.57%,rgba(0, 0, 0, 0) 190.2%)";
    }
    return isTransrateHeader ? "white" : "transparent";
  };

  return mobile ? (
    <>
      <div
        style={{
          background: isTransrateHeader || clickMenu ? "white" : "transparent",
          zIndex: 99999,
          top: 0,
          left: 0,
          height: 50,
          maxHeight: 75,
          boxShadow: isTransrateHeader
            ? "0px 3px 10px 0px rgba(0, 0, 0, 0.10)"
            : "",
        }}
        className="px-24 py-15 w-full fixed flex"
      >
        <div className="w-full flex flex-row justify-between items-center ">
          {windowSize.width > 600 ? (
            <Link to={"/"}>
              <img
                style={{
                  objectFit: "cover",
                  height: 20,
                }}
                src={Logo}
                height={20}
              />
            </Link>
          ) : (
            <Link to={"/"}>
              <img
                style={{
                  objectFit: "cover",
                  height: 20,
                }}
                src={isTransrateHeader || clickMenu ? Logo : MobileLogo}
                height={20}
              />{" "}
            </Link>
          )}

          <div className=" relative flex flex-row items-center  ">
            {mobileClickMenu ? (
              <div
                onClick={() => {
                  setMobileClickMenu(false);
                }}
                style={{
                  backgroundColor: GLOBLE_COLOR.PRIMARY,
                }}
                className=" absolute right-24 top-[-10px]  pt-10 px-10 rounded-t-20 rounded-b-20"
              >
                <img
                  style={{
                    objectFit: "contain",
                    width: 25,
                    minWidth: 25,
                    minHeight: 25,
                  }}
                  src={isTransrateHeader ? MobileLanBlack : MobileLan}
                />
                <div
                  style={{
                    backgroundColor: GLOBLE_COLOR.PRIMARY,
                  }}
                  className="flex flex-col  py-15 gap-y-20  left-0 text-center text-16 rounded-10"
                >
                  {["KR", "EN", "JP", "CN", "CT", "TH"].map((v, i) => {
                    return (
                      <div
                        className="font-bold"
                        onClick={() => {
                          localStorage.setItem("lang", v);
                          setUserLanguage(v as LanguageCode);
                        }}
                        style={{
                          color: currentLanguage === v ? "white" : "#C99C52",
                          textDecoration: "underline",
                        }}
                      >
                        {v}
                      </div>
                    );
                  })}
                </div>{" "}
              </div>
            ) : (
              <>
                <img
                  className="px-10"
                  onClick={() => {
                    setMobileClickMenu(true);
                    setClickMenu(false);
                  }}
                  style={{
                    objectFit: "contain",
                    width: 45,
                    minWidth: 25,
                    minHeight: 25,
                  }}
                  src={
                    isTransrateHeader || clickMenu ? MobileLanBlack : MobileLan
                  }
                />
              </>
            )}{" "}
            <div className="w-24 flex justify-center">
              <img
                onClick={() => {
                  setClickMenu((prev) => !prev);
                  setMobileClickMenu(false);
                }}
                style={{
                  objectFit: "contain",
                  width: 24,
                  height: 24,
                }}
                src={ImageRender()}
              />
            </div>
          </div>
        </div>{" "}
        {clickMenu && (
          <div className="absolute w-[calc(100%+10px)] bg-white flex flex-col gap-y-28 mx-[-25px] px-25 pt-30 text-18 top-50 h-[100vh] ">
            <Link to={"/moneyplex"}>
              <span
                onClick={() => {
                  setClickMenu(false);
                }}
                className="hover:text-primary "
              >
                {string[userLanguage].moneyplex}
              </span>
            </Link>
            <Link to={"/kiosklocation"}>
              <span
                onClick={() => {
                  setClickMenu(false);
                }}
                className="hover:text-primary "
              >
                {string[userLanguage].kioskLoation}
              </span>
            </Link>
            <Link to={"/inquery"}>
              <span
                onClick={() => {
                  setClickMenu(false);
                }}
                className="hover:text-primary "
              >
                {string[userLanguage].inquery}
              </span>
            </Link>
            <Link to={"/guide"}>
              <span
                onClick={() => {
                  setClickMenu(false);
                }}
                className="hover:text-primary "
              >
                {string[userLanguage].guide}
              </span>
            </Link>
            <Link to={"/event"}>
              <span
                onClick={() => {
                  setClickMenu(false);
                }}
                className="hover:text-primary "
              >
                {string[userLanguage].event}
              </span>
            </Link>
            <Link to={"/custermerservice"}>
              <span
                onClick={() => {
                  setClickMenu(false);
                }}
                className="hover:text-primary "
              >
                {string[userLanguage].cs}
              </span>
            </Link>
          </div>
        )}
      </div>
    </>
  ) : (
    <div
      style={{
        position: "fixed",
        height: 75,
        top: 0,
        left: 0,
        width: "100vw",
        background: renderHeaderbackground(),
        zIndex: 99999,
        boxShadow: isTransrateHeader
          ? "0px 6px 6.1px 0px rgba(0, 0, 0, 0.12)"
          : "",
      }}
      className="header flex flex-row h-[70px] items-center px-[15%] justify-between   border border-blue-500"
    >
      <Link to={"/"} className="">
        <div>
          <img
            style={{
              minHeight: 30,
              minWidth: 127,
            }}
            src={Logo}
            height={30}
            width={122}
          />
        </div>
      </Link>

      <div
        style={{
          color: isTransrateHeader ? "#1E2637" : "white",
        }}
        className="flex flex-row justify-evenly text-nowrap  items-center text-20 font-medium"
      >
        <Link to={"/moneyplex"}>
          <div
            onMouseEnter={() => {
              setIsHover(true);
            }}
            onMouseLeave={() => {
              setIsHover(false);
            }}
            className={`${
              currentPage === "moneyplex" && "text-primary"
            }  pr-45 hover:text-primary`}
          >
            {string[userLanguage].moneyplex}
          </div>
        </Link>
        <Link to={"/kiosklocation"}>
          <div
            onMouseEnter={() => {
              setIsHover(true);
            }}
            onMouseLeave={() => {
              setIsHover(false);
            }}
            className={`${
              currentPage === "kiosklocation" && "text-primary"
            }  pr-45 hover:text-primary`}
          >
            {string[userLanguage].kioskLoation}
          </div>
        </Link>
        <Link to={"/inquery"} className="flex-grow">
          <div
            onMouseEnter={() => {
              setIsHover(true);
            }}
            onMouseLeave={() => {
              setIsHover(false);
            }}
            className={`${
              currentPage === "inquery" && "text-primary"
            }  pr-45 hover:text-primary`}
          >
            {string[userLanguage].inquery}
          </div>
        </Link>
        <Link to={"/guide"}>
          <div
            onMouseEnter={() => {
              setIsHover(true);
            }}
            onMouseLeave={() => {
              setIsHover(false);
            }}
            className={`${
              currentPage === "guide" && "text-primary"
            }  pr-45 hover:text-primary`}
          >
            {string[userLanguage].guide}
          </div>
        </Link>
        <Link
          onMouseEnter={() => {
            setIsHover(true);
          }}
          onMouseLeave={() => {
            setIsHover(false);
          }}
          to={"/event"}
        >
          <div
            className={`${
              currentPage === "event" && "text-primary"
            }  pr-45 hover:text-primary`}
          >
            {string[userLanguage].event}
          </div>
        </Link>
        <Link
          onMouseEnter={() => {
            setIsHover(true);
          }}
          onMouseLeave={() => {
            setIsHover(false);
          }}
          to={"/custermerservice"}
        >
          <div
            onMouseEnter={() => {
              setIsHover(true);
            }}
            onMouseLeave={() => {
              setIsHover(false);
            }}
            className={`${
              currentPage === "custermerservice" && "text-primary"
            } hover:text-primary pr-45`}
          >
            {string[userLanguage].cs}
          </div>
        </Link>
        {webClickMenu ? (
          <div
            ref={modalRef}
            onClick={() => {
              setWebClickMenu(false);
            }}
            style={{
              backgroundColor: GLOBLE_COLOR.PRIMARY,
            }}
            className="flex-shrink-0  relative px-10 pt-10 mt-[-10px] rounded-t-20 mr-45"
          >
            <img
              className="cursor-pointer"
              src={isTransrateHeader ? World : White}
            />
            <div
              style={{
                backgroundColor: GLOBLE_COLOR.PRIMARY,
              }}
              className="flex flex-col py-15 gap-y-20 absolute w-47 left-0 text-center text-16 rounded-b-20 text-[#C99C52] "
            >
              {["KR", "EN", "JP", "CN", "CT", "TH"].map((v, i) => {
                return (
                  <div
                    className="font-bold "
                    onClick={() => {
                      localStorage.setItem("lang", v);
                      setUserLanguage(v as LanguageCode);
                    }}
                    style={{
                      color: currentLanguage === v ? "white" : "#C99C52",
                      textDecoration: "underline",
                    }}
                  >
                    {v}
                  </div>
                );
              })}
            </div>
          </div>
        ) : (
          <span
            onClick={() => {
              setWebClickMenu(true);
            }}
            style={{
              minWidth: 27,
              minHeight: 27,
            }}
            className="flex-shrink-0 px-10 pr-55 rounded-t-20 cursor-pointer"
          >
            <img src={isTransrateHeader ? World : White} />
          </span>
        )}
      </div>
    </div>
  );
}
