import React, {
  useEffect,
  useRef,
  useState,
  TouchEvent,
  useCallback,
} from "react";
import MainImgComponent from "./component/MainImgComponent";
import MobileAppKioskInfo from "./component/mobile/M_AppKioskInfo";
import MobileMainKioskImg from "./component/mobile/M_MainKioskImg";
import MobileMainImgComponent from "./component/mobile/M_MainImgComponent";
import MobilePartnerShip from "./component/mobile/M_PartnerShip";
import AppKioskInfo from "./component/AppKioskInfo";
import PartnerShip from "./component/PartnerShip";
import MainKioskImg from "./component/MainKioskImg";
import IntroductionPoint from "./component/IntroductionPoint";
import IntroductionInquiry from "./component/IntroductionInquiry";
import VaildMobile from "util/VaildMobile";
import MKioskLocation from "./component/mobile/M_KioskLocation";
import useWindowSizeCustom from "hooks/useWindowSizeCustom";
import useUserLanguage from "hooks/useUserLanguage";
import { useOutletContext } from "react-router-dom";
import { LanguageCode } from "types/Type";
import { debounce } from "lodash";
const TOTAL_SECTIONS = 7;
const SCROLL_TIMEOUT = 700;

export default function Home() {
  const isMobile = useWindowSizeCustom();
  const { userLanguage: language } = useOutletContext<{
    userLanguage: LanguageCode;
  }>();

  const HEADER_HEIGHT = isMobile.width < 900 ? 50 : 75;
  const TOUCH_SENSITIVITY = 50; // 터치 민감도 설정

  const [activeSection, setActiveSection] = useState<number>(0);
  const sections = useRef<(HTMLElement | null)[]>([]);
  const scrolling = useRef<boolean>(false);
  const scrolling2 = useRef<boolean>(false);
  const touchStart = useRef<number>(0);

  const scrollToSection = (index: number): void => {
    const targetSection = sections.current[index];
    if (!targetSection) return;

    const targetPosition = targetSection.offsetTop - HEADER_HEIGHT;

    window.scrollTo({
      top: targetPosition,
      behavior: "smooth",
    });
  };

  // 스크롤 위치 감지 핸들러
  const handleScrollPosition = (): void => {
    if (scrolling.current) return;

    const currentScrollPosition = window.scrollY + HEADER_HEIGHT;
    let newActiveSection = 0;

    sections.current.forEach((section, index) => {
      if (!section) return;

      const sectionTop = section.offsetTop;
      const sectionBottom = sectionTop + section.offsetHeight;

      if (
        currentScrollPosition >= sectionTop - window.innerHeight / 3 &&
        currentScrollPosition < sectionBottom - window.innerHeight / 3
      ) {
        newActiveSection = index;
      }
    });

    setActiveSection(newActiveSection);
  };

  // 터치 이벤트 핸들러
  const handleTouchStart = (e: TouchEvent): void => {
    touchStart.current = e.touches[0].clientY;
  };

  const handleTouchEnd = (e: TouchEvent): void => {
    if (scrolling.current) return;

    const touchEnd = e.changedTouches[0].clientY;
    const touchDiff = touchStart.current - touchEnd;

    if (Math.abs(touchDiff) < TOUCH_SENSITIVITY) return;

    scrolling.current = true;
    scrolling2.current = true;

    if (touchDiff > 0 && activeSection < TOTAL_SECTIONS - 1) {
      // 위로 스와이프
      scrollToSection(activeSection + 1);
      setActiveSection((prev) => prev + 1);
    } else if (touchDiff < 0 && activeSection > 0) {
      // 아래로 스와이프
      scrollToSection(activeSection - 1);
      setActiveSection((prev) => prev - 1);
    }

    setTimeout(() => {
      scrolling.current = false;
    }, SCROLL_TIMEOUT);

    setTimeout(() => {
      scrolling2.current = false;
    }, SCROLL_TIMEOUT + 300);
  };

  // 데스크톱 wheel 이벤트 핸들러
  const handleWheel = (e: WheelEvent): void => {
    e.preventDefault();

    if (isMobile.width < 500) return; // 모바일에서는 wheel 이벤트 무시

    if (scrolling.current || scrolling2.current) return;

    scrolling.current = true;
    scrolling2.current = true;
    if (
      (e.deltaY > 2 || e.deltaY === -0) &&
      activeSection < TOTAL_SECTIONS - 1
    ) {
      scrollToSection(activeSection + 1);
      setActiveSection((prev) => prev + 1);
    } else if ((e.deltaY < -2 || e.deltaY === -0) && activeSection > 0) {
      scrollToSection(activeSection - 1);
      setActiveSection((prev) => prev - 1);
    }

    setTimeout(() => {
      scrolling.current = false;
    }, SCROLL_TIMEOUT);
    setTimeout(() => {
      scrolling2.current = false;
    }, SCROLL_TIMEOUT + 300);
  };
  // 이벤트 리스너 등록
  useEffect(() => {
    window.addEventListener("scroll", handleScrollPosition);

    if (isMobile.width < 500) {
      // 모바일 환경에서는 터치 이벤트만 등록
      document.addEventListener("touchstart", handleTouchStart as any);
      document.addEventListener("touchend", handleTouchEnd as any);
    } else {
      // 데스크톱 환경에서는 wheel 이벤트 등록
      window.addEventListener("wheel", handleWheel, { passive: false });
    }

    return () => {
      window.removeEventListener("scroll", handleScrollPosition);
      document.removeEventListener("touchstart", handleTouchStart as any);
      document.removeEventListener("touchend", handleTouchEnd as any);
      window.removeEventListener("wheel", handleWheel);
    };
  }, [activeSection, isMobile.width]);

  return (
    <>
      {isMobile.width < 600 ? (
        <div className="overflow-hidden">
          <MobileMainImgComponent userLanguage={language} />
          <MobileAppKioskInfo userLanguage={language} />
          <MobilePartnerShip userLanguage={language} />
          <MKioskLocation userLanguage={language} />
        </div>
      ) : (
        <div className="min-w-[750px] overflow-hidden">
          <div ref={(el) => (sections.current[0] = el)}>
            <MainImgComponent userLanguage={language} />
          </div>
          <div>
            <AppKioskInfo userLanguage={language} sectionRef={sections} />
          </div>
          <div ref={(el) => (sections.current[4] = el)}>
            <PartnerShip userLanguage={language} />
          </div>
          <div ref={(el) => (sections.current[5] = el)}>
            <MainKioskImg userLanguage={language} />
          </div>
          <div ref={(el) => (sections.current[6] = el)}></div>
        </div>
      )}
    </>
  );
}
