import React from "react";
import { IMG_SOURE, TERMS_URL } from "../../const/const";
import Logo from "asset/image/logo.png";
import BlogIcon from "asset/image/blog_icon.png";
import InstaIcon from "asset/image/insta_icon.png";
import faceBookIcon from "asset/image/facebook_icon.png";
import isMobile from "util/VaildMobile";
import useWindowSizeCustom from "hooks/useWindowSizeCustom";
import { Link } from "react-router-dom";
import string from "locales";
import { LanguageProps } from "types/Type";
export default function Footer({ userLanguage }: LanguageProps) {
  const mobile = useWindowSizeCustom();

  return mobile.width < 750 ? (
    <div className="flex  bg-[#F0F2F4] justify-center py-20 px-24 text-12">
      <div className=" flex flex-col gap-y-[10px] font-medium leading-14 ">
        <span className=" inline-block">
          {string[userLanguage].companyName}
          <span className=" mx-4 text-[#969696]">|</span>{" "}
          {string[userLanguage].CEO}
        </span>
        {string[userLanguage].callCenter}
        <div> {string[userLanguage].helpEmail}: support@moneyplex.net</div>
        <div> {string[userLanguage].companyNumber}: 872-88-00742</div>
        <div>{string[userLanguage].etc1} </div>
        <div>{string[userLanguage].etc2} </div>
        <div>{string[userLanguage].address}</div>
        <div className="my-20">
          <div className="font-bold">
            <Link to={`/terms/${TERMS_URL.SERVICE_TERMS}`}>
              {string[userLanguage as "EN"].serviceTerms}
            </Link>
            <span className="font-medium text-[#969696] text-14 mx-10">|</span>
            <Link to={`/terms/${TERMS_URL.PRIVACY_POLICY}`}>
              {string[userLanguage as "EN"].privacyTerms}
            </Link>

            <span className="font-medium text-[#969696] text-14 mx-10">|</span>
            <Link to={`/terms/${TERMS_URL.OPEN_BANKING_TERMS}`}>
              {string[userLanguage as "EN"].openBankingTerms}
            </Link>
            {/* <span className="font-medium text-[#969696] text-14 mx-10">|</span>
            <Link to={`/terms/${TERMS_URL.MARKETING_TERMS}`}>
              {string[userLanguage as "EN"].markingTerms}
            </Link> */}
          </div>
        </div>
        <div className="mb-20 flex flex-row gap-x-[34px]">
          <a
            href="https://blog.naver.com/cointravit"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              style={{
                objectFit: "contain",
              }}
              src={BlogIcon}
              width={25}
              alt="Blog"
            />
          </a>

          <a
            href="https://www.instagram.com/moneyplex_cointravit/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              style={{
                objectFit: "contain",
              }}
              src={InstaIcon}
              width={25}
              alt="Instagram"
            />
          </a>

          <a
            href="https://www.facebook.com/moneyplexofficial"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              style={{
                objectFit: "contain",
              }}
              src={faceBookIcon}
              width={25}
              alt="Facebook"
            />
          </a>
        </div>
        <div className="w-full h-[1px] bg-[#e1e1e1]"></div>
        <div className="text-center mt-14 text-10">
          Copyright ©Cointravit Corp.
        </div>
      </div>
    </div>
  ) : (
    <>
      <div className="bg-[#F0F2F4] py-40 px-[20%] font-medium">
        <div className="flex">
          <div className="w-full flex flex-col gap-y-10 text-14 leading-20">
            <img width={140} height={34} src={Logo} />
            <div className="">
              {string[userLanguage].companyName}{" "}
              <span className="px-[10px]">|</span> {string[userLanguage].CEO}{" "}
              <span className="px-[10px]">|</span>
              {string[userLanguage].callCenter}
            </div>
            <div>
              {string[userLanguage].helpEmail}: support@moneyplex.net{" "}
              <span className="px-[10px]">|</span>
              {string[userLanguage].companyNumber}: 872-88-00742
            </div>
            <div>
              {string[userLanguage].etc1} <span className="px-[10px]">|</span>{" "}
              {string[userLanguage].etc2}
            </div>
            <div>{string[userLanguage].address}</div>
            <div className="mt-20 font-bold">
              <Link to={`/terms/${TERMS_URL.SERVICE_TERMS}`}>
                {string[userLanguage as "EN"].serviceTerms}
              </Link>
              <span className="font-medium text-[#969696] text-14 mx-10">
                |
              </span>
              <Link to={`/terms/${TERMS_URL.PRIVACY_POLICY}`}>
                {string[userLanguage as "EN"].privacyTerms}
              </Link>

              <span className="font-medium text-[#969696] text-14 mx-10">
                |
              </span>
              <Link to={`/terms/${TERMS_URL.OPEN_BANKING_TERMS}`}>
                {string[userLanguage as "EN"].openBankingTerms}
              </Link>
              {/* <span className="font-medium text-[#969696] text-14 mx-10">
                |
              </span>
              <Link to={`/terms/${TERMS_URL.MARKETING_TERMS}`}>
                {string[userLanguage as "EN"].markingTerms}
              </Link> */}
            </div>
          </div>
          <div className="flex items-end">
            <div className="flex flex-row gap-x-[34px]">
              <a
                href="https://blog.naver.com/cointravit"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  style={{
                    objectFit: "contain",
                  }}
                  src={BlogIcon}
                  width={45}
                  alt="Blog"
                />
              </a>

              <a
                href="https://www.instagram.com/moneyplex_cointravit/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  style={{
                    objectFit: "contain",
                  }}
                  src={InstaIcon}
                  width={45}
                  alt="Instagram"
                />
              </a>

              <a
                href="https://www.facebook.com/moneyplexofficial"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  style={{
                    objectFit: "contain",
                  }}
                  src={faceBookIcon}
                  width={45}
                  alt="Facebook"
                />
              </a>
            </div>
          </div>
        </div>

        <div className="h-1 bg-[#e1e1e1] w-[calc(100%+100px)] mt-20 mb-40">
          <div className="pt-20 block">Copyright ©Cointravit Corp.</div>
        </div>
      </div>
    </>
  );
}
