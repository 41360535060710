import React, { useEffect, useState } from "react";
import KioskButton from "./component/KioskButton";
import KioskList from "./component/KioskList";
import KioskMap from "./component/KioskMap";
import axios from "axios";
import TabButton from "component/common/TabButton";
import string from "locales";
import { LanguageCode } from "types/Type";
import { useOutletContext } from "react-router-dom";
import useWindowSizeCustom from "hooks/useWindowSizeCustom";
export default function KioskLocatoin() {
  const { userLanguage } = useOutletContext<{
    userLanguage: LanguageCode;
  }>();
  const [currentTab, setCurrentTab] = useState(string[userLanguage].MapList);
  const [kioskData, setKioskData] = useState<any>();
  const [isLoading, setIsLoading] = useState(true);
  const [currentLanguage, setCurrentLanguage] = useState("");
  const [selectedMarker, setSelectedMarker] = useState<any>();
  const windowSize = useWindowSizeCustom();
  const selectCurrentLang = () => {
    let language;
    switch (userLanguage) {
      case "CN":
        language = "_cn";
        break;
      case "CT":
        language = "_tw";
        break;
      case "EN":
        language = "_en";
        break;
      case "JP":
        language = "_jp";
        break;
      case "KR":
        language = "";
        break;
      case "TH":
        language = "_th";
        break;
    }
    setCurrentLanguage(language);
  };
  useEffect(() => {
    selectCurrentLang();
  }, []);

  useEffect(() => {
    setCurrentTab(string[userLanguage].MapList);
    selectCurrentLang();
  }, [userLanguage]);
  let config = {
    method: "get",
    url: "/mpx_api/kioskinfo",
    headers: {
      "X-API-KEY": process.env.REACT_APP_MPX_API_KEY,
      "Content-Type": "application/json",
    },
  };

  useEffect(() => {
    if (windowSize.width < 800) {
      setCurrentTab(string[userLanguage].MapList);
    }
  }, [windowSize.width]);

  useEffect(() => {
    axios
      .request(config)
      .then((response) => {
        setKioskData(() => {
          let kioskObject: any = {};
          response.data.areaList.forEach(
            (v: { changerList: any; areaNm: string }) => {
              const language = localStorage.getItem("lang");
              kioskObject[string[language as "EN"][v.areaNm as "부산"]] =
                v.changerList;
            }
          );

          setIsLoading(false);
          return kioskObject;
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }, [userLanguage]);

  return (
    <div
      style={{
        paddingTop: windowSize.width < 500 ? 30 : 93,
        minHeight: 700,
        marginBottom: currentTab === "list" ? 100 : 0,
        display: "flex",
        flexDirection: "column",
      }}
      className=" sm:px-25"
    >
      {windowSize.width > 800 && (
        <TabButton
          currentTab={currentTab}
          setCurrentTab={setCurrentTab}
          leftText={string[userLanguage].MapList}
          rightText={string[userLanguage].MapMap}
        />
      )}

      {currentTab === string[userLanguage].MapList ? (
        <KioskList
          isLoading={isLoading}
          kioskData={kioskData}
          currentLanguage={currentLanguage}
          userLanguage={userLanguage}
          setCurrentTab={setCurrentTab}
          setSelectedMarker={setSelectedMarker}
          windowSize={windowSize}
        />
      ) : (
        kioskData && (
          <KioskMap
            kioskData={kioskData}
            userLanguage={userLanguage}
            currentLanguage={currentLanguage}
            selectMarker={selectedMarker}
            setSelectedMarker={setSelectedMarker}
          />
        )
      )}
    </div>
  );
}
