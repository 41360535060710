export const MainPageCN = {
  MaintitleBody1: '与预付费交通卡一起',
  MaintitleBody2: '外汇兑换 MoneyPlex！',
  MaintitleSubBody: '立即体验智能且便捷的外汇兑换服务',
  MaincardSectionTitle: '预付费交通卡发行',
  MaincardSectionBody1: '通过 MoneyPlex 发行，',
  MaincardSectionBody2: '从公共交通到购物，',
  MaincardSectionBody3: '随时随地轻松便捷支付',
  MaincardSectionBody4: '内外籍人士皆可使用',
  MainappSectionTitle: '快速便捷的外汇预约 及多种合作优 惠券福利！',
  MainappSectionSubBody: 'MoneyPlex 还帮您 管理兑换后剩 余的零钱',
  MainkioskSectionTitle: '支持发行和充 值预付费交通卡的 自助终端机',
  MainkioskSectionBody:
    '支持100种货币，6种语言，发行与充值预付费交通卡，支持免税店、百货商店及 酒店会员卡 的多功能自助终 端机',
  MainpartnerSectionTitle: '合作伙伴',
  MainpartnerSectionBody1:
    '"不仅为 外国客户提供 预付费交 通卡，还支 持无人卡 发放，能够在无 需运营负担的 情况下 扩大商 业客户基础，这 是国内唯一 的解决 方案。"',
  MainpartnerSectionBody2: '',
  MainpartnerSectionInqueryButton: '申请咨询',
  MainmobileTilte1: '兑换的新标准，MoneyPlex！',
  MainmobileTilte2: '请到现场直接确认。',
};

export const MoneyPlexCN = {
  MoneyplexTabLeft: 'MoneyPlex 客户',
  MoneyplexTabRight: '商务咨询',
  MoneyplexKioskTitle: '还在为兑换外币四处奔波吗？现在体验 MoneyPlex 外汇兑换自助终端机吧',
  MoneyplexExchangeProcess: '兑换流程',
  MoneyplexExchange1Title: '步骤 1：选择要兑换的外币',
  MoneyplexExchange1Body:
    '支持多种外币，您可以选择美元（USD）、欧元（EUR）、日元（JPY）、人民币（CNY）等主要15种货币。',
  MoneyplexExchange2Title: '步骤 2：扫描身份证件（护照）',
  MoneyplexExchange2Body: '身份证件（护照）信息是必需的，这是在韩国兑换外币的基本程序。',
  MoneyplexExchange3Title: '步骤 3：投入外币纸币',
  MoneyplexExchange3Body:
    '现在是将您选择的外币投入自助终端机的步骤。投入的纸币将被自动识别，计算兑换金额。',
  MoneyplexExchange4Title: '步骤 4：领取韩元',
  MoneyplexExchange4Body:
    '兑换完成后，您可以从自助终 端机领取韩元。兑换金额将 以现金形式立 即支付。兑换完成后，将打印收据。',
  MoneyplexAppTitle:
    'MoneyPlex APP 提供从外汇兑 换到积分支付的 多种功能。通过一个移动应用随 时随地轻松 进行外汇兑换。',
  MoneyplexApp1Title: '自助终端机位置搜索',
  MoneyplexApp1Body:
    '通过 MoneyPlex 应用可以实时 查看附近的外汇兑换 自助终端机位置，帮助 您快速找 到终端机进 行外汇兑换。',
  MoneyplexApp2Title: 'MoneyPlex 会员',
  MoneyplexApp2Body:
    'MoneyPlex APP 用户通过 简单的会员认 证可以享受 多种福利。完成 会员认证后，可在兑换 预约 时享受积分累 积及额外折扣优惠。（仅限韩国用户）',
  MoneyplexApp3Title: 'MoneyPlex 外汇预约（仅限韩国用户）',
  MoneyplexApp3Body:
    'MoneyPlex 应用为韩国用户 提供外汇预约功能，您 可以以低廉的汇率提前 预约外币兑换。在您期望的汇率时点进行预约，轻松完成兑换。',
  MoneyplexApp4Title: '积分商城优惠券（仅限韩国用户）',
  MoneyplexApp4Body:
    '通过 MoneyPlex 会员的积分商城优惠券购买功能，您可以使 用积累的积分购买多种合作 伙伴的优惠券。通过兑 换外汇累 积积分，享受多种福利。',
  MoneyplexBusinessTitle: '国内领先的无人外汇兑换自助终端机解决方案',
  MoneyplexBusinessBody:
    '24小时运营、降低成本、提高客户满意度，帮助合作伙伴取得成功的最佳解决方案。',
  MoneyplexFindKiosk: '查找自助终端机位置',
  MoneyplexBenefitTitle1: '支持24小时运营',
  MoneyplexBenefitBody1: '"客户可随时兑 换外币，延长服务 提供 时间，最大化客 户满意度。"',
  MoneyplexBenefitTitle2: '预付交通卡&合作商户卡支持',
  MoneyplexBenefitBody2: '"不仅可以发放预付交通卡，还可以发行会员专用卡，让客户的选择范围更广。”',
  MoneyplexBenefitTitle3: '提升客户满意度',
  MoneyplexBenefitBody3:
    '"特别是在游客 众多的酒店、赌场、大型超市等场所，通过自助终 端机最大化客户 的便利性。”',
  MoneyplexBenefitTitle4: '安装和维护便捷',
  MoneyplexBenefitBody4: '"安装简便，维护工作最小化，合作伙 伴无需复杂的管 理流程即可轻松 运营。”',
  MoneyplexBenefitTitle5: '创造盈利机会',
  MoneyplexBenefitBody5:
    '"安装自助 终端机不仅 可以获得设 备收益，还能通过客户 流量增加额外 收入。”',
  MoneyplexPartnerBenefitHeader: '合作伙伴福利',
  MoneyplexPartnerBenefitHeader2: 'MoneyPlex 自助终端机安装情况及合作伙伴福利',
  MoneyplexPartnerBenefitTitle1: '提升客户满意度',
  MoneyplexPartnerBenefitBody1: '提供24小时外汇兑换服务',
  MoneyplexPartnerBenefitTitle2: '降低成本',
  MoneyplexPartnerBenefitBody2: '零人工成本，自动化运营系统',
  MoneyplexPartnerBenefitTitle3: '创造额外收益',
  MoneyplexPartnerBenefitBody3: '客户流量增加带来的额外收入',
  MoneyplexPartnerBenefitTitle4: '便捷的管理',
  MoneyplexPartnerBenefitBody4: '通过运营管理系统进行实时故障监控',
  MoneyplexKioskExampleTitle: '自助终端机安装案例',
  MoneyplexKioskExamplelocation1: '酒店 • 赌场',
  MoneyplexKioskExamplebody1: '安装在首尔主要酒店和赌场。',
  MoneyplexKioskExamplelocation2: '港口 • 火车站',
  MoneyplexKioskExamplebody2: '安装在邮轮港、首尔站等。',
  MoneyplexKioskExamplelocation3: '百货商店 • 大型超市',
  MoneyplexKioskExamplebody3: '在全国23家大型超市安装。',
  MoneyplexCustmerReview: '客户评价',
  MoneyplexCustmerTitle: '实际用户评价与成功案例',
};
export const GuidePageCN = {
  GuideKioskBill: '支持货币',
  GuideKioskCurrencyExchange: '外币兑换',
  GuideKioskPoint: '积分累积',
  GuideKioskPointSub: '应用积累/条码积累',
  GuideKioskCardIssue: '预付卡发行与充值',
  GuideKioskRecive: '领取方式',

  GuideAppReservation: '外币兑换预约',
  GuideAppSignUp: '注册',
  GuideAppCoupon: '下载优惠券',
  GuideDetailAppConponText1: '01 优惠券',
  GuideDetailAppConponText2: '02 输入优惠券代码',
  GuideDetailAppConponText3: '03 优惠券注册完成',
  GuideDetailAppConponText4: '04 优惠券可用',
  GuideDetailAppReservation1: '01 外币兑换预约',
  GuideDetailAppReservation2: '02 选择分店',
  GuideDetailAppReservation3: '03 选择兑换日期',
  GuideDetailAppReservation4: '04 选择金额和货币',
  GuideDetailAppReservation5: '05 提交兑换预约',
  GuideDetailAppReservation6: '06 预约完成',
  GuideDetailAppReservation7: '07 管理预约',
  GuideDetailAppReservation8: '08 存款完成',
  GuideDetailAppReservation9: '09 自助服务机领取',
  GuideDetailKioskBill1: '01 扫描护照',
  GuideDetailKioskBill2: '02 支持货币',
  GuideDetailKioskCurrencyExchange1: '01 选择外币',
  GuideDetailKioskCurrencyExchange2: '02 确认支持货币',
  GuideDetailKioskCurrencyExchange3: '03 投入纸币',
  GuideDetailKioskCurrencyExchange4: '04 确认纸币金额',
  GuideDetailKioskPointSubTitle1: '应用积累 - MoneyPlex 手机充值',
  GuideDetailKioskPointSubTitle2: 'KB 钱包',
  GuideDetailKioskPointSubTitle3: '领取找零',
  GuideDetailKioskPointSubTitle4: '捐赠',
  GuideDetailKioskPointApp1: '选择 MoneyPlex 手机充值',
  GuideDetailKioskPointApp2: '选择会员',
  GuideDetailKioskPointApp3: '会员 - 条码',
  GuideDetailKioskPointApp4: '非会员 - 输入电话号码',
  GuideDetailKioskPointApp5: '非会员 - 输入电话号码',
  GuideDetailKioskPointApp6: '充值完成',
  GuideDetailKioskKb1: '选择国家钱包',
  GuideDetailKioskKb2: '同意',
  GuideDetailKioskKb3: 'Kb国家钱包',
  GuideDetailKioskKb4: '选择国家钱包',
  GuideDetailKioskKb5: '条形码标签',
  GuideDetailKioskKb6: '充电完成',
  GuideDetailKioskCoin1: '选择接收韩元',
  GuideDetailKioskCoin2: '选择找零',
  GuideDetailKioskCoin3: '选择会员',
  GuideDetailKioskCoin4: '会员 - 条码',
  GuideDetailKioskCoin5: '非会员 - 输入电话号码',
  GuideDetailKioskCoin6: '非会员 - 输入电话号码',
  GuideDetailKioskCoin7: '充值完成',
  GuideDetailKioskReturn1: '选择接收韩元',
  GuideDetailKioskReturn2: '选择捐赠',
  GuideDetailKioskReturn3: '捐赠完成',
  GuideDetailCardInfo: '预付卡可以在klook提前申请并发放。',
  GuideDetailSignUp1: '01 注册',
  GuideDetailSignUp2: '02 同意',
  GuideDetailSignUp3: '03 邮箱验证',
  GuideDetailSignUp4: '04 设置密码',
  GuideDetailSignUp5: '05 设置安全密码',
  GuideDetailSignUp6: '06 注册完成',

  GuideDetailReceiveMoneyTitle1: '现金领取',
  GuideDetailReciveMoneyTitle2: '머니플렉스 앱 충전',

  GuideDetailReceiveMoneySubTitle1: '找零领取',
  GuideDetailReceiveMoney1_1: '01 选择韩元收款',
  GuideDetailReceiveMoney1_2: '02 选择找零收款方式',
  GuideDetailReceiveMoney1_3: '03 选择会员/非会员',
  GuideDetailReceiveMoney1_4: '04 输入号码/扫描条形码',
  GuideDetailReceiveMoney1_5: '05 收取韩元',
  GuideDetailReceiveMoney1_6: '06 完成',

  GuideDetailReceiveMoneySubTitle2: '捐赠（找零）',
  GuideDetailReceiveMoney2_1: '01 选择现金领取',
  GuideDetailReceiveMoney2_2: '02 选择找零领取',
  GuideDetailReceiveMoney2_3: '03 选择捐赠',
  GuideDetailReceiveMoney2_4: '04 完成',

  GuideDetailReceiveMoneySubTitle3: 'MoneyFlex应用充电',
  GuideDetailReceiveMoney3_1: '01 交通卡充值',
  GuideDetailReceiveMoney3_2: '02 选择会员或非会员',
  GuideDetailReceiveMoney3_3: '03 非会员 - 输入号码',
  GuideDetailReceiveMoney3_4: '04 会员 - 输入条形码',
  GuideDetailReceiveMoney3_5: '05 完成',

  GuideDetailReceiveMoneySubTitle4: 'KB国民钱包',
  GuideDetailReceiveMoney4_1: '01 选择KB国民钱包',
  GuideDetailReceiveMoney4_2: '02 同意个人信息',
  GuideDetailReceiveMoney4_3: '03 扫描条形码',
  GuideDetailReceiveMoney4_4: '04 充值完成',

  GuideDetailReceiveMoneySubTitle5: '捐赠（全部金额捐赠）',
  GuideDetailReceiveMoney5_1: '01 选择捐赠',
  GuideDetailReceiveMoney5_2: '02 捐赠完成',
  GuideToolTip1: '请在下方的优惠券标签中注册。\n(针对外国人)',
  GuideToolTip2: '登录时需要设置会员密码。',
  GuideToolTip3: '需要设置6位数的安全密码。\n(在使用应用和积分时)',
  GuideToolTip4: '可选择3个工作日后的日期',
  GuideToolTip5:
    '您需要将兑换金额存入账户。\n当天24点之前的入金才有效。\n(由于汇率波动，超过24点后将被取消)',
  GuideToolTip6: '入金完成后，如果请求取消或退款，将会扣除1%的手续费。',
  GuideToolTip7: '会员情况下 - 条形码扫描',
  GuideToolTip8: '非会员情况下 - 输入手机号码',
  GotoKlook: '访问 klook',
  HighlightText: '当天24点',
  GuideBill: '纸币',
  GuideCoin: '硬币',
  GuideOldBank: '旧钞票',
  GuideNewBank: '新钞票',
  GuideSubText: '1000韩元 以下只能在 收取韩元 时充入MoneyPlex或捐赠。',
};
export const MapPageCN = {
  서울: 'Seoul',
  부산: 'Busan',
  경기: 'Gyeonggi',
  인천: 'Incheon',
  MapList: 'List',
  MapMap: 'Map',
  MapOpenTime: '营业时间',
  MapMapButton: '地图',
  MapLength: '个网点',
  MapForiegn: 'Foriegn Exchange',
};
export const InqeryPageCN = {
  InqueryFormName: '公司名称',
  InqueryFormNumber: '联系电话',
  InqueryFormPrivate: '同意收集个人信息',
  InqueryPlaceHolderName: '请输入公司名称',
  InqueryPlaceHolderNumber: '请输入您的联系电话。',
  InqueryPrivateText: '您输入的信息不会用于任何超出指定范围的目的。',
  InqueryTitle1: '请轻松咨询关于引入',
  InqueryTitle2: 'MoneyPlex的问题！',
  InqueryApply: '申请',
  CSTitle: '我们能为您做些什么？',
  CSPlaceHolder: '如果您有任何疑问，可以尝试搜索关键词。',
  InqueryCheckText: '同意收集个人信息（必填）',
  CSFAQ: '常见问题',

  CsAnnouncements: '通告',
  CSTotal: 'Toal',
  CSKiosk: 'Kiosk',
  CSETC: 'ETC',

  CSMoneyplex: 'MoneyPlex APP',
  InquerySuccess: '您的咨询已成功发送。',
  CSNotFound: '没有找到结果。',
  AnnounceNotFound: '沒有公告。',
};
