export const MainPageEN = {
  MaintitleBody1: "With a prepaid transportation card",
  MaintitleBody2: "Currency exchange with MoneyPlex!",
  MaintitleSubBody:
    "Experience the smart and convenient\ncurrency exchange service now",
  MaincardSectionTitle: "Prepaid Transportation Card Issuance",
  MaincardSectionBody1: "Get it from MoneyPlex,",
  MaincardSectionBody2: "from public transportation to shopping,",
  MaincardSectionBody3: "easy and convenient payment anywhere",
  MaincardSectionBody4: "Available for both locals and foreigners",
  MainappSectionTitle:
    "Fast and convenient currency exchange reservations and various partner coupons!",
  MainappSectionSubBody:
    "MoneyPlex manages even the leftover change after currency exchange",
  MainkioskSectionTitle:
    "Kiosks for issuing and recharging prepaid transportation cards",
  MainkioskSectionBody:
    "Supports 100 currency types, 6 languages, and offers prepaid transportation card issuance and recharging, as well as duty-free shops, department stores, and hotel, membership cards via multi-kiosk",
  MainpartnerSectionTitle: "Partners",
  MainpartnerSectionBody1:
    '"This is the only solution in the country that not only provides prepaid transportation cards for foreign customers but also supports unmanned card issuance, allowing businesses to expand their customer base without the burden of operations."',
  MainpartnerSectionBody2: "",
  MainpartnerSectionInqueryButton: "Inquire about onboarding",
  MainmobileTilte1: "The new standard in currency exchange, MoneyPlex!",
  MainmobileTilte2: "Check it out in person at the site.",
};
export const MoneyPlexEN = {
  MoneyplexTabLeft: "MoneyPlex Customer",
  MoneyplexTabRight: "Business Inquiry",
  MoneyplexKioskTitle:
    "Are you still running around different places to exchange currency? Meet the MoneyPlex Exchange Kiosk now!",
  MoneyplexExchangeProcess: "Currency Exchange Process",
  MoneyplexExchange1Title: "Step 1: Select the foreign currency to exchange",
  MoneyplexExchange1Body:
    "We support a variety of foreign currencies, including major currencies such as the US Dollar (USD), Euro (EUR), Japanese Yen (JPY), and Chinese Yuan (CNY), among 15 countries.",

  MoneyplexExchange2Title: "Step 2: Scan your ID (passport)",
  MoneyplexExchange2Body:
    "ID (passport) verification is mandatory, as this is a basic procedure for currency exchange in Korea.",
  MoneyplexExchange3Title: "Step 3: Insert foreign currency bills",
  MoneyplexExchange3Body:
    "Now, it's time to insert the selected foreign currency into the kiosk. The kiosk will automatically recognize the inserted bills and calculate the exchange amount.",
  MoneyplexExchange4Title: "Step 4: Receive Korean Won",
  MoneyplexExchange4Body:
    "Once the exchange is completed, you can receive the Korean Won from the kiosk. The exchanged amount will be paid in cash immediately. A receipt will be printed once the exchange is finished.",
  MoneyplexAppTitle:
    "The MoneyPlex APP offers various features, from currency exchange to point payment. Easily exchange currency anytime and anywhere with just one mobile app.",

  MoneyplexApp1Title: "Kiosk Location Search",
  MoneyplexApp1Body:
    "With the MoneyPlex app, you can check the location of nearby exchange kiosks in real time. It helps you quickly find a kiosk for easy currency exchange.",

  MoneyplexApp2Title: "MoneyPlex Membership",
  MoneyplexApp2Body:
    "MoneyPlex APP users can enjoy various benefits through simple membership verification. Once verified, users can earn points and receive additional discounts when reserving currency exchanges. (Only For Koreans)",
  MoneyplexApp3Title: "MoneyPlex Exchange Reservation (Only For Koreans)",
  MoneyplexApp3Body:
    "Through the exchange reservation feature, available to Korean nationals, you can reserve currency exchanges at favorable exchange rates in advance. Reserve your exchange at your preferred rate for a convenient experience.",
  MoneyplexApp4Title: "Point Shop Coupons (Only For Koreans)",
  MoneyplexApp4Body:
    "Through the Point Shop coupon purchasing feature for MoneyPlex members, you can use accumulated points to buy various partner coupons. Accumulate points while exchanging currency and enjoy diverse benefits.",
  MoneyplexBusinessTitle:
    "The Best Automated Currency Exchange Kiosk Solution in Korea",
  MoneyplexBusinessBody:
    "We provide the optimal solution for partner success, including 24-hour operation, cost savings, and improved customer satisfaction.",
  MoneyplexFindKiosk: "Find Kiosk Locations",
  MoneyplexBenefitTitle1: "24-Hour Operation",
  MoneyplexBenefitBody1:
    '"Customers can exchange currency regardless of time, extending service hours and maximizing customer satisfaction."',
  MoneyplexBenefitTitle2:
    "Prepaid Transportation Cards & Partner Merchant Card Support",
  MoneyplexBenefitBody2:
    '"Not only can prepaid transportation cards be issued, but member-exclusive cards are also available, giving customers a wider range of options."',
  MoneyplexBenefitTitle3: "Increased Customer Satisfaction",
  MoneyplexBenefitBody3:
    '"Especially in locations like hotels, casinos, and large supermarkets frequented by foreign tourists, kiosks maximize customer convenience."',
  MoneyplexBenefitTitle4: "Easy Installation and Maintenance",
  MoneyplexBenefitBody4:
    '"The kiosks are easy to install and require minimal maintenance, allowing partners to operate them without complex management procedures."',
  MoneyplexBenefitTitle5: "Revenue Generation Opportunities",
  MoneyplexBenefitBody5:
    '"Installing kiosks not only generates revenue from the machines themselves but also increases customer traffic, leading to additional revenue opportunities."',
  MoneyplexPartnerBenefitHeader: "Partner Benefits",
  MoneyplexPartnerBenefitHeader2:
    "Moneyplex Kiosk Installation Status and Partner Benefits",
  MoneyplexPartnerBenefitTitle1: "Enhanced Customer Satisfaction",
  MoneyplexPartnerBenefitBody1: "24-hour currency exchange service",
  MoneyplexPartnerBenefitTitle2: "Cost Savings",
  MoneyplexPartnerBenefitBody2: "Zero labor costs, automated operation system",
  MoneyplexPartnerBenefitTitle3: "Additional Revenue Generation",
  MoneyplexPartnerBenefitBody3:
    "Increased customer traffic leads to additional sales",
  MoneyplexPartnerBenefitTitle4: "Easy Management",
  MoneyplexPartnerBenefitBody4:
    "Real-time issue monitoring through the operations management system",
  MoneyplexKioskExampleTitle: "Kiosk Installation Examples",
  MoneyplexKioskExamplelocation1: "Hotels • Casinos",
  MoneyplexKioskExamplebody1: "Installed in major hotels and casinos in Seoul.",
  MoneyplexKioskExamplelocation2: "Ports • Train Stations",
  MoneyplexKioskExamplebody2: "Installed at cruise ports, Seoul Station, etc.",
  MoneyplexKioskExamplelocation3: "Department Stores • Large Supermarkets",
  MoneyplexKioskExamplebody3: "Installed in 23 large supermarkets nationwide.",
  MoneyplexCustmerReview: "Customer Reviews",
  MoneyplexCustmerTitle: "Real User Feedback and Success Stories",
};
export const GuidePageEN = {
  GuideKioskBill: "Supported Currency",
  GuideKioskCurrencyExchange: "Foreign Exchange",
  GuideKioskPoint: "Point Charge",
  GuideKioskPointSub: "App Reward / Barcode Reward",
  GuideKioskCardIssue: "Prepaid Card Issuance and Recharge",
  GuideKioskRecive: "Receive Method",

  GuideAppReservation: "Currency Exchange Reservation",
  GuideAppSignUp: "Sign Up",
  GuideAppCoupon: "Download Coupon",
  GuideDetailAppConponText1: "01 Coupon",
  GuideDetailAppConponText2: "02 Enter Coupon Code",
  GuideDetailAppConponText3: "03 Coupon Registration Complete",
  GuideDetailAppConponText4: "04 Coupon Available for Use",
  GuideDetailAppReservation1: "01 Currency Exchange Reservation",
  GuideDetailAppReservation2: "02 Select Branch",
  GuideDetailAppReservation3: "03 Select Exchange Date",
  GuideDetailAppReservation4: "04 Select Amount and Currency",
  GuideDetailAppReservation5: "05 Submit Currency Exchange Reservation",
  GuideDetailAppReservation6: "06 Reservation Complete",
  GuideDetailAppReservation7: "07 Manage Reservation",
  GuideDetailAppReservation8: "08 Payment Completed",
  GuideDetailAppReservation9: "09 Kiosk Pickup",
  GuideDetailKioskBill1: "01 Scan Passport",
  GuideDetailKioskBill2: "02 Supported Currency",
  GuideDetailKioskCurrencyExchange1: "01 Select Foreign Currency",
  GuideDetailKioskCurrencyExchange2: "02 Confirm Supported Currency",
  GuideDetailKioskCurrencyExchange3: "03 Insert Bill",
  GuideDetailKioskCurrencyExchange4: "04 Confirm Bill Amount",
  GuideDetailKioskPointSubTitle1:
    "App Accumulation - MoneyPlex Mobile Recharge",
  GuideDetailKioskPointSubTitle2: "KB Wallet",
  GuideDetailKioskPointSubTitle3: "Receive Change",
  GuideDetailKioskPointSubTitle4: "Donate",
  GuideDetailKioskPointApp1: "Select MoneyPlex Mobile Recharge",
  GuideDetailKioskPointApp2: "Select Member",
  GuideDetailKioskPointApp3: "Member - Barcode",
  GuideDetailKioskPointApp4: "Nonmember - Enter Phone Number",
  GuideDetailKioskPointApp5: "Nonmember - Enter Phone Number",
  GuideDetailKioskPointApp6: "Recharge Complete",
  GuideDetailKioskKb1: "Select KB Wallet",
  GuideDetailKioskKb2: "Agree to Terms",
  GuideDetailKioskKb3: "KBWallet",
  GuideDetailKioskKb4: "Select KB Wallet",
  GuideDetailKioskKb5: "Barcode Tagging",
  GuideDetailKioskKb6: "Charging Complete",

  GuideDetailKioskCoin1: "Select KRW Receipt",
  GuideDetailKioskCoin2: "Select Change Receipt",
  GuideDetailKioskCoin3: "Select Member",
  GuideDetailKioskCoin4: "Member - Barcode",
  GuideDetailKioskCoin5: "Nonmember - Enter Phone Number",
  GuideDetailKioskCoin6: "Nonmember - Enter Phone Number",
  GuideDetailKioskCoin7: "Recharge Complete",
  GuideDetailKioskReturn1: "Select KRW Receipt",
  GuideDetailKioskReturn2: "Select Donate",
  GuideDetailKioskReturn3: "Donation Complete",
  GuideDetailCardInfo:
    "Prepaid cards can be applied for and issued in advance on klook.",
  GuideDetailSignUp1: "01 Sign Up",
  GuideDetailSignUp2: "02 Agree",
  GuideDetailSignUp3: "03 Email Verification",
  GuideDetailSignUp4: "04 Set Password",
  GuideDetailSignUp5: "05 Set Security Password",
  GuideDetailSignUp6: "06 Sign Up Complete",

  GuideDetailReceiveMoneyTitle1: "Cash Receipt",
  GuideDetailReciveMoneyTitle2: "머니플렉스 앱 충전",

  GuideDetailReceiveMoneySubTitle1: "Change Receipt",
  GuideDetailReceiveMoney1_1: "01 Select KRW Receipt",
  GuideDetailReceiveMoney1_2: "02 Select Change Receipt Method",
  GuideDetailReceiveMoney1_3: "03 Select Member/Non-member",
  GuideDetailReceiveMoney1_4: "04 Enter Number/Scan Barcode",
  GuideDetailReceiveMoney1_5: "05 Receive KRW",
  GuideDetailReceiveMoney1_6: "06 Complete",

  GuideDetailReceiveMoneySubTitle2: "Donation (Change)",
  GuideDetailReceiveMoney2_1: "01 Select Cash Receipt",
  GuideDetailReceiveMoney2_2: "02 Select Change Receipt",
  GuideDetailReceiveMoney2_3: "03 Select Donation",
  GuideDetailReceiveMoney2_4: "04 Complete",

  GuideDetailReceiveMoneySubTitle3: "MoneyFlex App Recharge",
  GuideDetailReceiveMoney3_1: "01 Recharge Transport Card",
  GuideDetailReceiveMoney3_2: "02 Select Member or Nonmember",
  GuideDetailReceiveMoney3_3: "03 Nonmember - Enter Number",
  GuideDetailReceiveMoney3_4: "04 Member - Enter Barcode",
  GuideDetailReceiveMoney3_5: "05 Complete",

  GuideDetailReceiveMoneySubTitle4: "KB National Wallet",
  GuideDetailReceiveMoney4_1: "01 Select KB National Wallet",
  GuideDetailReceiveMoney4_2: "02 Agree to Personal Information",
  GuideDetailReceiveMoney4_3: "03 Scan Barcode",
  GuideDetailReceiveMoney4_4: "04 Recharge Complete",

  GuideDetailReceiveMoneySubTitle5: "Donation (Total Donation)",
  GuideDetailReceiveMoney5_1: "01 Select Donation",
  GuideDetailReceiveMoney5_2: "02 Donation Complete",

  GuideToolTip1:
    "Please register in the coupon tab at the bottom.\n(Targeting foreigners)",
  GuideToolTip2: "You need to set a member password upon login.",
  GuideToolTip3:
    "A 6-digit security password is required.\n(When using the app and points)",
  GuideToolTip4: "You can select a date starting from 3 business days later.",
  GuideToolTip5:
    "You must deposit the exchange amount into your account to proceed.\nDeposits are only valid until 24:00 on the same day.\n(The exchange rate may fluctuate, so it will be canceled after 24:00)",
  GuideToolTip6:
    "If you request cancellation or a refund after the deposit is completed, 1% of the fee will be deducted from the refunded amount.",
  GuideToolTip7: "Member: Scan the barcode",
  GuideToolTip8: "NonMember: Enter the mobile phone number",

  GotoKlook: "Go To klook",
  HighlightText: "until 24:00",
  GuideBill: "Bill",
  GuideCoin: "Coin",
  GuideOldBank: "Old Banknotes",
  GuideNewBank: "New Banknotes",
  GuideSubText:
    "Amounts below 1,000 won can only be charged to MoneyPlex or donated when receiving in won.",
};
export const MapPageEN = {
  서울: "Seoul",
  부산: "Busan",
  경기: "Gyeonggi",
  인천: "Incheon",
  MapList: "List",
  MapMap: "Map",
  MapOpenTime: "OPEN",
  MapMapButton: "Map",
  MapLength: "locations",
  MapForiegn: "Foriegn Exchange",
};
export const InqeryPageEN = {
  InqueryTitle1: "Feel free to inquire about",
  InqueryTitle2: "introducing MoneyPlex!",
  InqueryFormName: "Company Name",
  InqueryFormNumber: "Contact Number",
  InqueryFormPrivate: "Consent to Collect Personal Information",
  InqueryPlaceHolderNumber: "Please enter your contact number.",
  InqueryPlaceHolderName: "Please enter the company name",
  InqueryPrivateText:
    "The information you entered will not be used for any purposes other than those specified.",
  InqueryApply: "Apply",
  CSTitle: "How can we assist you?",
  CSPlaceHolder: "If you have any questions, try searching for a keyword.",
  InqueryCheckText: "Consent to Collect Personal Information (Required)",
  CSFAQ: "FAQ",
  CsAnnouncements: "Announcements",
  CSTotal: "Toal",
  CSKiosk: "Kiosk",
  CSMoneyplex: "MoneyPlex APP",
  CSETC: "ETC",

  InquerySuccess: "Your inquiry has been successfully sent.",
  CSNotFound: "No results found.",
  AnnounceNotFound: "There are no announcements.",
};
