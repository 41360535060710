import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Layout from './Layout';
import Home from 'pages/HomePage/Home';
import KioskLocatoin from 'pages/KioskLocationPage/KioskLocation';
import CustermerService from 'pages/CustermerServicePage/CustermerService';
import Inquery from 'pages/InqueryPage/Inquery';
import Guide from 'pages/GuidePage/Guide';
import MoneyPlex from 'pages/MoneyPlexPage/MoneyPlex';
import GuideDetailPage from 'pages/GuideDetailPage/GuideDetailPage';
import EventPage from 'pages/EventPage/EventPage';
import EventDetailPage from 'pages/EventDetailPage/EventDetailPage';
import TermsPage from 'pages/TermsPage/TermsPage';
export default function CommonRouter() {
  return (
    <Routes>
      <Route element={<Layout />}>
        <Route path='/' element={<Home />}></Route>
        <Route path='kiosklocation' element={<KioskLocatoin />}></Route>
        <Route path='custermerservice' element={<CustermerService />}></Route>
        <Route path='inquery' element={<Inquery />}></Route>
        <Route path='guide' element={<Guide />}></Route>
        <Route path='moneyplex' element={<MoneyPlex />}></Route>
        <Route path='guide/:id' element={<GuideDetailPage />}></Route>
        <Route path='event' element={<EventPage />}></Route>
        <Route path='event/:id' element={<EventDetailPage />}></Route>
        <Route path='terms/:id' element={<TermsPage />}></Route>
      </Route>
    </Routes>
  );
}
