import React, { ReactElement, useEffect, useState } from "react";
import Faq from "asset/image/FaQArrow.png";
import Error from "asset/image/Error.png";
import string from "locales";
import { LanguageCode } from "types/Type";
import parse, { domToReact } from "html-react-parser";
const AnncounceComponent = ({
  List,
  setClickIndex,
  clickIndex,
  userLanguage,
}: {
  List: { title: string; body: string; date?: string }[];
  setClickIndex: any;
  clickIndex: number;
  userLanguage: LanguageCode;
  isAnnounce?: boolean;
}) => {
  if (List.length === 0) {
    return (
      <div
        style={{}}
        className="flex flex-col items-center text-gray8080 text-40 font-medium gap-y-10 mt-30"
      >
        <img width={41} src={Error}></img>
        {string[userLanguage].AnnounceNotFound}
      </div>
    );
  }
  const options = {
    replace: (domNode: any) => {
      // p 태그를 div로 감싸기
      if (domNode.name === "p") {
        return <div>{domToReact(domNode.children)}</div>;
      }
    },
  };
  return (
    <>
      {" "}
      <div className="h-1 bg-[#D9D9D9] mt-20"></div>
      {List.map((v, index, array) => {
        const isClicked = index === clickIndex;
        return (
          <>
            <div className="w-full cursor-pointer" key={index}>
              <div
                onClick={() => {
                  if (isClicked) {
                    setClickIndex(-1);
                  } else {
                    setClickIndex(index);
                  }
                }}
                style={{
                  backgroundColor: "#FFFFFF",
                  borderBottom: "1px solid #A2AAB4",
                }}
                className={`py-20  flex flex-col w-[100%]`}
              >
                <div className="flex text-left   flex-row items-center justify-between  px-15">
                  <span className="text-12 sm:text-20 font-medium ">
                    {v.title}
                  </span>
                  <img
                    style={{
                      transform: isClicked ? "rotate(180deg)" : "rotate(0)",
                      transition: "transform 0.4s ease",
                    }}
                    src={Faq}
                    width={30}
                    height={30}
                  />
                </div>
                <div className=" px-15 text-left mt-10 text-[#A2AAB4] text-12 sm:text-20">
                  {v.date}
                </div>
              </div>
              <div
                style={{
                  cursor: "default",
                  maxHeight: isClicked ? "500px" : "0px",
                  overflow: "hidden",
                  transition: "max-height 0.7s ease",
                }}
                className="flex flex-col"
              >
                <span
                  style={{
                    backgroundColor: "#f8f8f8 !important",
                  }}
                  className="font-medium flex flex-col  text-[#717073] text-12 sm:text-20 break-keep text-start bg-[#f8f8f8] py-[5%] px-15 leading-30 overflow-y-scroll"
                >
                  {parse(v.body, options)}
                </span>
              </div>
            </div>
          </>
        );
      })}
    </>
  );
};
const FaqComponent: any = ({
  List,
  setClickIndex,
  clickIndex,
  userLanguage,
  isAnnounce = false,
}: {
  List: { KR: { title: string; body: string; date?: string }[] };
  setClickIndex: any;
  clickIndex: number;
  userLanguage: LanguageCode;
  isAnnounce?: boolean;
}) => {
  return List[userLanguage as "KR"]?.length === 0 ? (
    <div className="flex flex-col items-center text-gray8080 text-40 font-medium gap-y-10">
      <img width={41} src={Error}></img>
      {string[userLanguage].CSNotFound}
    </div>
  ) : (
    <>
      <div className="h-1 bg-[#D9D9D9] mt-20"></div>
      {List[userLanguage as "KR"]?.map((v, index) => {
        const isClicked = index === clickIndex;
        return (
          <>
            <div className="w-full cursor-pointer" key={index}>
              <div
                onClick={() => {
                  if (isClicked) {
                    setClickIndex(-1);
                  } else {
                    setClickIndex(index);
                  }
                }}
                style={{
                  backgroundColor: "#FFFFFF",
                  borderBottom: "1px solid #A2AAB4",
                }}
                className={`py-20  flex flex-col w-[100%]`}
              >
                <div className="flex text-left   flex-row items-center justify-between  px-15">
                  <span className="text-12 sm:text-20 font-bold w-[85%] sm:w-[100%]  break-all">
                    {v.title}
                  </span>
                  <img
                    style={{
                      transform: isClicked ? "rotate(180deg)" : "rotate(0)",
                      transition: "transform 0.4s ease",
                    }}
                    src={Faq}
                    width={30}
                    height={30}
                  />
                </div>
              </div>
              <div
                style={{
                  cursor: "default",
                  maxHeight: isClicked ? "500px" : "0px",
                  overflow: "hidden",
                  transition: "max-height 0.7s ease",
                }}
              >
                <span className="font-medium flex text-[#717073] text-12 sm:text-20  break-all text-start bg-[#F8F8F8] py-[5%] px-15 leading-30  sm:w-[100%] ">
                  {v.body}
                </span>
              </div>
            </div>
          </>
        );
      })}
    </>
  );
};

const FaqSearchComponent: any = ({
  List,
  userLanguage,
  searchKeyWord,
}: {
  List: { title: string; body: string }[];
  setClickIndex: any;
  clickIndex: number;
  userLanguage: LanguageCode;
  isAnnounce?: boolean;
  searchKeyWord: string;
}) => {
  const [ListOpenArray, setListOpenArray] = useState(
    Array(List.length).fill(true)
  );
  console.log(ListOpenArray);
  useEffect(() => {
    setListOpenArray(Array(List.length).fill(true));
  }, [List.length]);
  const highlightText = (text: string, keyword: string) => {
    if (!keyword || !text) return text;

    const parts = text.split(new RegExp(`(${keyword})`, "gi"));

    return (
      <span className="whitespace-pre-wrap">
        {parts.map((part, i) => {
          if (part.toLowerCase() === keyword.toLowerCase()) {
            return (
              <span key={i} className="font-bold bg-yellow-200 inline">
                {part}
              </span>
            );
          }
          return (
            <span key={i} className="inline">
              {part}
            </span>
          );
        })}
      </span>
    );
  };

  return List.length === 0 ? (
    <div className="flex flex-col items-center text-gray8080 text-40 font-medium gap-y-10 mt-50">
      <img width={41} src={Error}></img>
      {string[userLanguage].CSNotFound}
    </div>
  ) : (
    <>
      <div className="h-1 bg-[#D9D9D9] mt-20"></div>
      {List?.map((v, index) => {
        return (
          <>
            <div className="w-full cursor-pointer" key={index}>
              <div
                style={{
                  backgroundColor: "#FFFFFF",
                  borderBottom: "1px solid #A2AAB4",
                }}
                className={`py-20  flex flex-col w-[100%]`}
              >
                <div
                  onClick={() => {
                    setListOpenArray((prev) => {
                      let newArray = [...prev]; // 기존 배열을 복사합니다.
                      newArray[index] = !newArray[index]; // 현재 인덱스의 값을 반전시킵니다.
                      return newArray; // 업데이트된 배열을 반환합니다.
                    });
                  }}
                  className="flex text-12 sm:text-20 text-left flex-row items-center justify-between font-bold  px-15"
                >
                  {highlightText(v.title, searchKeyWord)}
                  <img
                    style={{
                      transform: ListOpenArray[index]
                        ? "rotate(180deg)"
                        : "rotate(0)",
                      transition: "transform 0.4s ease",
                    }}
                    src={Faq}
                    width={30}
                    height={30}
                  />
                </div>
              </div>
              <div
                style={{
                  cursor: "default",
                  maxHeight: ListOpenArray[index] ? "500px" : "0px",
                  overflow: "hidden",
                  transition: "max-height 0.7s ease",
                }}
              >
                <span className="font-medium flex  text-[#717073] text-12 sm:text-20 break-keep text-start bg-[#F8F8F8] py-[5%] px-15 leading-30">
                  {highlightText(v.body, searchKeyWord)}
                </span>
              </div>
            </div>
          </>
        );
      })}
    </>
  );
};
interface FaqItem {
  title: string;
  body: string;
}

interface FaqData {
  KR: FaqItem[];
}
export default function FaqList({
  faqList,
  userLanguage,
  faqCategory,
  setFaqCategory,
  searchWord,
  setSearchWord,
  debouncedValue,
  setDebouncedValue,
  announceList,
}: {
  announceList: any;
  faqList: any;
  userLanguage: LanguageCode;
  faqCategory: string;
  setFaqCategory: any;
  searchWord: string;
  setSearchWord: any;
  debouncedValue: string;
  setDebouncedValue: any;
}) {
  const [clickIndex, setClickIndex] = useState(-1);

  const [selectCategory, setSelectCategory] = useState(
    string[userLanguage].CSFAQ
  );
  useEffect(() => {
    setSelectCategory(string[userLanguage].CSFAQ);
    setFaqCategory(string[userLanguage].CSTotal);
  }, [userLanguage]);
  return (
    <div className="max-w-[2000px] mx-auto flex justify-center mb-50">
      <div className="w-full px-24 min-h-[200px] md:w-[70%]">
        <div className="w-full flex flex-row gap-x-30 text-18 sm:text-24 mb-60 justify-center">
          {[
            string[userLanguage].CSFAQ,
            string[userLanguage].CsAnnouncements,
          ].map((v, i) => {
            return (
              <div
                onClick={() => {
                  setSelectCategory(v);
                  setClickIndex(-1);
                }}
                className="flex flex-col items-center justify-center font-semibold "
              >
                <div
                  className={`${
                    selectCategory === v ? "text-primary" : "text-[#A2AAB4]"
                  } `}
                >
                  {v}
                </div>
                {selectCategory === v && (
                  <div
                    className={`  relative top-2 w-full h-[2px] bg-primary`}
                  ></div>
                )}
              </div>
            );
          })}
        </div>
        {selectCategory === string[userLanguage].CSFAQ ? (
          <>
            {searchWord === "" && (
              <div className="flex items-start text-center mb-45 gap-x-24 text-14 sm:text-24 ">
                {[
                  string[userLanguage].CSTotal,
                  string[userLanguage].CSKiosk,
                  string[userLanguage].CSMoneyplex,
                ].map((v) => {
                  return (
                    <div
                      onClick={() => {
                        setFaqCategory(v);
                        setClickIndex(-1);
                        setDebouncedValue("");
                      }}
                      className={`${
                        faqCategory === v
                          ? "bg-primary text-white"
                          : "bg-[#F0F2F4] text-[#A2AAB4]"
                      }  flex px-20 py-10 items-center justify-center rounded-30 font-medium `}
                    >
                      {v}
                    </div>
                  );
                })}
              </div>
            )}
            {searchWord !== "" && faqList["KR"] === undefined ? (
              <FaqSearchComponent
                List={faqList}
                setClickIndex={setClickIndex}
                clickIndex={clickIndex}
                userLanguage={userLanguage}
                searchKeyWord={searchWord}
              />
            ) : (
              <FaqComponent
                List={faqList}
                setClickIndex={setClickIndex}
                clickIndex={clickIndex}
                userLanguage={userLanguage}
              />
            )}
          </>
        ) : (
          <>
            <AnncounceComponent
              List={announceList}
              setClickIndex={setClickIndex}
              clickIndex={clickIndex}
              userLanguage={userLanguage}
              isAnnounce={true}
            />
          </>
        )}
      </div>
    </div>
  );
}
