import TabButton from "component/common/TabButton";
import React, { useState } from "react";
import AppChangeMoney from "asset/image/Guide_App_Change_Money.png";
import AppSignUp from "asset/image/Guide_APP_SignUp.png";
import AppCoupon from "asset/image/Guide_APP_Coupon.png";
import AppSetting from "asset/image/Guide_App_Setting.png";
import KioskBillImg from "asset/image/KioskBillIMG.png";
import KioskPointImg from "asset/image/KioskPointIMG.png";
import KioskCurrencyImg from "asset/image/KioskCurrencyIMG.png";
import KioskCardImg from "asset/image/KioskCardIMG.png";
import ReciveMoneyImg from "asset/image/revicemoney.png";
import { Link, useOutletContext } from "react-router-dom";
import { GUIDE_URL } from "const/const";
import { LanguageCode } from "types/Type";
import string from "locales";
import { fontSize32 } from "const/fontSize";
export default function Guide() {
  const [currentTab, setCurrentTab] = useState("KIOSK");
  const { userLanguage } = useOutletContext<{
    userLanguage: LanguageCode;
  }>();
  return (
    <div className="max-w-[2000px] mx-auto mb-100">
      <div className="pt-93 mb-30  px-30 sm:mb-140 ">
        <TabButton
          currentTab={currentTab}
          setCurrentTab={setCurrentTab}
          leftText={"KIOSK"}
          rightText={"APP"}
        />
      </div>
      <div className=" grid grid-cols-2  gap-x-10 gap-y-10 px-30  sm:px-[18%] sm:gap-x-30 sm:gap-y-50 sm:mb-50  md:grid-cols-2 lg:grid-cols-3">
        {currentTab === "KIOSK" ? (
          <>
            <Link to={GUIDE_URL.KIOSK_BILL}>
              <div
                className={`h-140 p-10  sm:h-[275px] sm:px-35 sm:py-44 text-14 font-bold ${fontSize32} sm:h-[311px]`}
                style={{
                  backgroundSize: "contain",
                  backgroundRepeat: "no-repeat",
                  backgroundImage: `url(${KioskBillImg})`,
                }}
              >
                {string[userLanguage].GuideKioskBill}
              </div>
            </Link>
            <Link to={GUIDE_URL.KIOSK_CURRENCY_EXCHANGE}>
              <div
                className={`h-140 p-10  sm:h-[275px] sm:px-35 sm:py-44 text-14 font-bold ${fontSize32} sm:h-[311px]`}
                style={{
                  backgroundSize: "contain",
                  backgroundRepeat: "no-repeat",
                  backgroundImage: `url(${KioskCurrencyImg})`,
                }}
              >
                {string[userLanguage].GuideKioskCurrencyExchange}
              </div>
            </Link>
            <Link to={GUIDE_URL.KIOSK_POINT}>
              <div
                className={` h-140 p-10  sm:h-[275px] sm:px-35 sm:py-44 text-14 font-bold ${fontSize32} sm:h-[311px]`}
                style={{
                  backgroundSize: "contain",
                  backgroundRepeat: "no-repeat",
                  backgroundImage: `url(${KioskPointImg})`,
                }}
              >
                {string[userLanguage].GuideKioskPoint}
              </div>
            </Link>
            <Link to={GUIDE_URL.KIOSK_CARD_ISSUE}>
              <div
                className={` h-140 p-10  sm:h-[275px] sm:px-35 sm:py-44 text-14 font-bold ${fontSize32} sm:h-[311px]`}
                style={{
                  backgroundSize: "contain",
                  backgroundRepeat: "no-repeat",
                  backgroundImage: `url(${KioskCardImg})`,
                }}
              >
                {string[userLanguage].GuideKioskCardIssue}
              </div>
            </Link>
            <Link to={GUIDE_URL.KIOSK_RECIVE_MONEY}>
              <div
                className={`h-140 p-10  sm:h-[275px] sm:px-35 sm:py-44 text-14 font-bold ${fontSize32} sm:h-[311px]`}
                style={{
                  backgroundSize: "contain",
                  backgroundRepeat: "no-repeat",
                  backgroundImage: `url(${ReciveMoneyImg})`,
                }}
              >
                {string[userLanguage].GuideKioskRecive}
              </div>
            </Link>
          </>
        ) : (
          <>
            <Link to={GUIDE_URL.APP_RESERVATION}>
              <div
                className={`h-140 p-10  sm:h-[275px] sm:px-35 sm:py-44 text-14 font-bold ${fontSize32} sm:h-[311px]`}
                style={{
                  backgroundSize: "contain",
                  backgroundRepeat: "no-repeat",
                  backgroundImage: `url(${AppChangeMoney})`,
                }}
              >
                {string[userLanguage].GuideAppReservation}
              </div>
            </Link>
            <Link to={GUIDE_URL.APP_SIGNUP}>
              <div
                className={`h-140 p-10  sm:h-[275px] sm:px-35 sm:py-44 text-14 font-bold ${fontSize32} sm:h-[311px]`}
                style={{
                  backgroundSize: "contain",
                  backgroundRepeat: "no-repeat",
                  backgroundImage: `url(${AppSignUp})`,
                }}
              >
                {string[userLanguage].GuideAppSignUp}
              </div>
            </Link>
            <Link to={GUIDE_URL.APP_COUPON}>
              <div
                className={`h-140 p-10  sm:h-[275px] sm:px-35 sm:py-44 text-14 font-bold ${fontSize32} sm:h-[311px]`}
                style={{
                  backgroundSize: "contain",
                  backgroundRepeat: "no-repeat",
                  backgroundImage: `url(${AppCoupon})`,
                }}
              >
                {string[userLanguage].GuideAppCoupon}
              </div>
            </Link>
          </>
        )}
      </div>
    </div>
  );
}
