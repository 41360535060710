export const fontSize40 = '2xl:text-40 xl:text-36 lg:text-32 md:text-28 ';
export const fontSize36 = '2xl:text-36 xl:text-32 lg:text-28 md:text-24';
export const fontSize34 = '2xl:text-34 xl:text-30 lg:text-26 md:text-22';
export const fontSize32 = '2xl:text-32 xl:text-28 lg:text-24 md:text-20';
export const fontSize30 = '2xl:text-30 xl:text-26 lg:text-22 md:text-18';
export const fontSize28 = '2xl:text-28 xl:text-24 lg:text-20 md:text-16 ';
export const fontSize26 = '2xl:text-26 xl:text-22 lg:text-18 md:text-14 ';
export const fontSize24 = '2xl:text-24 xl:text-20 lg:text-16 md:text-12 ';
export const fontSize20 = '2xl:text-20 xl:text-16 lg:text-12 md:text-12 ';

export const leading50 = '2xl:leading-50 xl:leading-45 lg:leading-40 max-md: leading-35';
