export const MainPageTH = {
  MaintitleBody1: "พร้อมกับบัตรโดยสารพรีเพด",
  MaintitleBody2: "แลกเงินต่างประเทศกับ MoneyPlex!",
  MaintitleSubBody: "พบกับบริการแลกเปลี่ยนเงินที่ชาญฉลาดและสะดว\nกสบายได้เลย",
  MaincardSectionTitle: "การออกบัตรโดยสารพรีเพด",
  MaincardSectionBody1: "ออกบัตรได้ที่ MoneyPlex,",
  MaincardSectionBody2: "จากการขนส่งสาธารณะไปจนถึงการช้อปปิ้ง,",
  MaincardSectionBody3: "จ่ายเงินได้ง่ายและสะดวกทุกที่ทุกเวลา",
  MaincardSectionBody4: "ใช้ได้ทั้งคนไทยและต่างชาติ",
  MainappSectionTitle:
    "การจองแลกเงินที่รวดเร็วและสะดวก พร้อมคูปองจากพันธมิตรมากมาย!",
  MainappSectionSubBody:
    "MoneyPlex ยังจัดการเงินที่เหลือจากการแลกเปลี่ยนให้ด้วย",
  MainkioskSectionTitle: "คีออสก์ที่สามารถออกและเติมเงินบัตรโดยสารพรีเพด",
  MainkioskSectionBody:
    "รองรับ 100 สกุลเงิน และ 6 ภาษา สามารถออกและเติมเงินบัตรโดยสารพรีเพด, พร้อมรองรับบัตรสมาชิกในร้านปลอดภาษี ห้างสรรพสินค้า และโรงแรมได้ในเครื่องเดียว",
  MainpartnerSectionTitle: "พันธมิตร",
  MainpartnerSectionBody1:
    '"ไม่เพียงแต่มีบั ตรโดยสารแบ บพรีเพดสำ หรับลูกค้า ชาวต่างประเ ทศ แต่ยังสนั บสนุนการออก บัตรแบบไร้คน ดูแล และสาม ารถขยายฐานลูกค้า ธุรกิจโด  "',
  MainpartnerSectionBody2: "",
  MainpartnerSectionInqueryButton: "สอบถามการติดตั้ง",
  MainmobileTilte1: "มาตรฐานใหม่ของการแลกเปลี่ยนเงินตรา มันนี่เฟล็กซ์!",
  MainmobileTilte2: "โปรดตรวจสอบด้วยตัวเองที่สถานที่",
  GuideSubText:
    "จำนวนเงินน้อย กว่า 1000 วอน สาม ารถเติม เงินใน Moneyfle x หรือบริจาคเ ท่านั้นเมื่อรั บเงินวอน",
};

export const MoneyPlexTH = {
  MoneyplexTabLeft: "ลูกค้า MoneyPlex",
  MoneyplexTabRight: "สอบถามธุรกิจ",
  MoneyplexKioskTitle:
    "ยังเดินหาแลกเงินต่างประเทศตามที่ต่างๆ อยู่หรือเปล่า? มาพบกับคีออสก์แลกเงินของ MoneyPlex เลย",
  MoneyplexExchangeProcess: "ขั้นตอนการแลกเปลี่ยน",
  MoneyplexExchange1Title: "ขั้นตอนที่ 1: เลือกสกุลเงินที่ต้องการแลก",
  MoneyplexExchange1Body:
    "รองรับหลายสกุลเงิน คุณสามารถเลือก ดอลลาร์สหรัฐ (USD), ยูโร (EUR), เยนญี่ปุ่น (JPY), หยวนจีน (CNY) และสกุลเงินหลักอื่นๆ ของ 15 ประเทศได้",
  MoneyplexExchange2Title: "ขั้นตอนที่ 2: สแกนบัตรประชาชน (พาสปอร์ต)",
  MoneyplexExchange2Body:
    "ข้อมูลบัตรประชาชน (พาสปอร์ต) เป็นสิ่งที่จำเป็น นี่คือขั้นตอนพื้นฐานในการแลกเงินต่างประเทศในเกาหลี",
  MoneyplexExchange3Title: "ขั้นตอนที่ 3: ใส่ธนบัตรต่างประเทศ",
  MoneyplexExchange3Body:
    "ถึงเวลาที่จะใส่ธนบัตรต่างประเทศที่คุณเลือกลงในคีออสก์แล้ว ธนบัตรที่ใส่จะถูกตรวจสอบโดยอัตโนมัติและคำนวณจำนวนเงินที่แลก",
  MoneyplexExchange4Title: "ขั้นตอนที่ 4: รับเงินวอน",
  MoneyplexExchange4Body:
    "เมื่อแลกเปลี่ยนเสร็จสิ้น คุณสามารถรับเงินวอนจากคีออสก์ได้ทันที จะมีการพิมพ์ใบเสร็จออกมาหลังจากการแลกเสร็จสิ้น",
  MoneyplexAppTitle:
    "MoneyPlex APP ให้บริการหลากหลายฟังก์ชัน ตั้งแต่การแลกเปลี่ยนเงินไปจนถึงการชำระเงินด้วยคะแนน ใช้งานง่ายทุกที่ทุกเวลาด้วยแอปพลิเคชันเดียว",
  MoneyplexApp1Title: "ค้นหาตำแหน่งคีออสก์",
  MoneyplexApp1Body:
    "คุณสามารถค้นหาตำแหน่งคีออสก์แลกเงินใกล้เคียงแบบเรียลไทม์ผ่านแอป MoneyPlex เพื่อช่วยให้คุณแลกเปลี่ยนเงินได้อย่างรวดเร็ว",
  MoneyplexApp2Title: "MoneyPlex สมาชิก",
  MoneyplexApp2Body:
    "ผู้ใช้แอป MoneyPlex สามารถเพลิดเพลินกับสิทธิประโยชน์ต่างๆ ได้ด้วยการยืนยันสมาชิกง่ายๆ เมื่อการยืนยันเสร็จสิ้น คุณจะได้รับคะแนนสะสมและส่วนลดเพิ่มเติมเมื่อจองการแลกเงิน (เฉพาะสำหรับชาวเกาหลีเท่านั้น)",
  MoneyplexApp3Title: "การจองแลกเงิน MoneyPlex (เฉพาะสำหรับชาวเกาหลี)",
  MoneyplexApp3Body:
    "ฟังก์ชันจองแลกเงินของแอป MoneyPlex ช่วยให้คุณสามารถจองแลกเงินล่วงหน้าด้วยอัตราแลกเปลี่ยนที่ถูกกว่า สะดวกและรวดเร็วเมื่อถึงอัตราที่ต้องการ",
  MoneyplexApp4Title: "คูปองจากร้านค้าที่ใช้คะแนนสะสม (เฉพาะสำหรับชาวเกาหลี)",
  MoneyplexApp4Body:
    "ฟังก์ชันซื้อคูปองจากร้านค้าของพันธมิตรโดยใช้คะแนนสะสมของสมาชิก MoneyPlex ให้คุณซื้อคูปองจากคะแนนที่สะสมไว้และเพลิดเพลินกับสิทธิประโยชน์มากมาย",
  MoneyplexBusinessTitle:
    "โซลูชันคีออสก์แลกเงินแบบไร้พนักงานที่ดีที่สุดในประเทศ",
  MoneyplexBusinessBody:
    "ให้บริการ 24 ชั่วโมง ประหยัดค่าใช้จ่าย และเพิ่มความพึงพอใจของลูกค้า เรามอบโซลูชันที่ดีที่สุดสำหรับความสำเร็จของพันธมิตร",
  MoneyplexFindKiosk: "ค้นหาตำแหน่งคีออสก์",
  MoneyplexBenefitTitle1: "ให้บริการได้ตลอด 24 ชั่วโมง",
  MoneyplexBenefitBody1:
    '"ลูกค้าสามารถแลกเงินได้ทุกเวลา ขยายเวลาการให้บริการและเพิ่มความพึงพอใจของลูกค้า"',
  MoneyplexBenefitTitle2: "บัตรโดยสารแบบพรีเพดและการสนับสนุนบัตรจากพันธมิตร",
  MoneyplexBenefitBody2:
    '"ไม่เพียงแต่การออกบัตรโดยสารแบบพรีเพดเท่านั้น แต่ยังสามารถออกบัตรเฉพาะสมาชิกได้ด้วย ทำให้ลูกค้ามีตัวเลือกที่หลากหลายมากขึ้น"',
  MoneyplexBenefitTitle3: "เพิ่มความพึงพอใจของลูกค้า",
  MoneyplexBenefitBody3:
    '"โดยเฉพาะอย่างยิ่งในสถานที่ที่มีนักท่องเที่ยวต่างชาติมาก เช่น โรงแรม คาสิโน หรือตลาดใหญ่ การใช้คีออสก์ช่วยเพิ่มความสะดวกสบายสูงสุดแก่ลูกค้า"',
  MoneyplexBenefitTitle4: "การติดตั้งและบำรุงรักษาที่ง่าย",
  MoneyplexBenefitBody4:
    '"การติดตั้งง่าย และการบำรุงรักษาน้อย ทำให้พันธมิตรสามารถดำเนินการได้อย่างง่ายดายโดยไม่ต้องมีขั้นตอนการจัดการที่ซับซ้อน"',
  MoneyplexBenefitTitle5: "โอกาสในการสร้างรายได้",
  MoneyplexBenefitBody5:
    '"การติดตั้งคีออสก์ไม่เพียงแต่สร้างรายได้จากอุปกรณ์ แต่ยังสามารถเพิ่มรายได้เพิ่มเติมจากการเพิ่มจำนวนลูกค้า"',
  MoneyplexPartnerBenefitHeader: "สิทธิประโยชน์สำหรับพันธมิตร",
  MoneyplexPartnerBenefitHeader2:
    "สถานะการติดตั้งคีออสก์ MoneyPlex และสิทธิประโยชน์สำหรับพันธมิตร",
  MoneyplexPartnerBenefitTitle1: "เพิ่มความพึงพอใจของลูกค้า",
  MoneyplexPartnerBenefitBody1: "ให้บริการแลกเงินตลอด 24 ชั่วโมง",
  MoneyplexPartnerBenefitTitle2: "ลดค่าใช้จ่าย",
  MoneyplexPartnerBenefitBody2:
    "ไม่มีค่าใช้จ่ายแรงงาน ระบบการดำเนินงานอัตโนมัติ",
  MoneyplexPartnerBenefitTitle3: "สร้างรายได้เพิ่มเติม",
  MoneyplexPartnerBenefitBody3: "เพิ่มยอดขายจากการเพิ่มจำนวนลูกค้า",
  MoneyplexPartnerBenefitTitle4: "การจัดการที่สะดวก",
  MoneyplexPartnerBenefitBody4:
    "ระบบการจัดการที่สามารถติดตามข้อผิดพลาดได้แบบเรียลไทม์",
  MoneyplexKioskExampleTitle: "ตัวอย่างการติดตั้งคีออสก์",
  MoneyplexKioskExamplelocation1: "โรงแรม • คาสิโน",
  MoneyplexKioskExamplebody1: "ติดตั้งในโรงแรมและคาสิโนหลักในกรุงโซล",
  MoneyplexKioskExamplelocation2: "ท่าเรือ • สถานีรถไฟ",
  MoneyplexKioskExamplebody2: "ติดตั้งที่ท่าเรือสำราญ สถานีโซล ฯลฯ",
  MoneyplexKioskExamplelocation3: "ห้างสรรพสินค้า • ซุปเปอร์มาร์เก็ตใหญ่",
  MoneyplexKioskExamplebody3: "ติดตั้งในซุปเปอร์มาร์เก็ตใหญ่ 23 แห่งทั่วประเทศ",
  MoneyplexCustmerReview: " รีวิวจากลูกค้า",
  MoneyplexCustmerTitle: "ความคิดเห็นของผู้ใช้จริงและกรณีศึกษาความสำเร็จ ",
};

export const GuidePageTH = {
  GuideKioskBill: "สกุลเงินที่รองรับ",
  GuideKioskCurrencyExchange: "แลกเปลี่ยนเงินตราต่างประเทศ",
  GuideKioskPoint: "สะสมคะแนน",
  GuideKioskPointSub: "สะสมผ่านแอป/บาร์โค้ด",
  GuideKioskCardIssue: "ออกบัตรเติมเงินและเติมเงิน",
  GuideKioskRecive: "วิธีการรับสินค้า",

  GuideAppReservation: "จองแลกเปลี่ยนเงินตรา",
  GuideAppSignUp: "สมัครสมาชิก",
  GuideAppCoupon: "ดาวน์โหลดคูปอง",
  GuideDetailAppConponText1: "01 แท็บคูปองด้านล่าง",
  GuideDetailAppConponText2: "02 ใส่รหัสคูปอง",
  GuideDetailAppConponText3: "03 ลงทะเบียนคูปองเสร็จสิ้น",
  GuideDetailAppConponText4: "04 คูปองพร้อมใช้งาน",
  GuideDetailAppReservation1: "01 จองแลกเปลี่ยนเงินตรา",
  GuideDetailAppReservation2: "02 เลือกสาขา",
  GuideDetailAppReservation3: "03 เลือกวันที่แลกเปลี่ยน",
  GuideDetailAppReservation4: "04 เลือกจำนวนและสกุลเงิน",
  GuideDetailAppReservation5: "05 ส่งคำขอแลกเปลี่ยนเงินตรา",
  GuideDetailAppReservation6: "06 จองเสร็จสิ้น",
  GuideDetailAppReservation7: "07 จัดการการจอง",
  GuideDetailAppReservation8: "08 ชำระเงินเสร็จสิ้น",
  GuideDetailAppReservation9: "09 รับที่คีออสก์",
  GuideDetailKioskBill1: "01 สแกนหนังสือเดินทาง",
  GuideDetailKioskBill2: "02 สกุลเงินที่รองรับ",
  GuideDetailKioskCurrencyExchange1: "01 เลือกสกุลเงินต่างประเทศ",
  GuideDetailKioskCurrencyExchange2: "02 ยืนยันสกุลเงินที่รองรับ",
  GuideDetailKioskCurrencyExchange3: "03 ใส่ธนบัตร",
  GuideDetailKioskCurrencyExchange4: "04 ยืนยันจำนวนธนบัตร",
  GuideDetailKioskPointSubTitle1: "สะสมผ่านแอป - MoneyPlex เติมเงินมือถือ",
  GuideDetailKioskPointSubTitle2: "กระเป๋าเงิน KB",
  GuideDetailKioskPointSubTitle3: "รับเงินทอน",
  GuideDetailKioskPointSubTitle4: "บริจาค",
  GuideDetailKioskPointApp1: "เลือกเติมเงินมือถือ MoneyPlex",
  GuideDetailKioskPointApp2: "เลือกสมาชิก",
  GuideDetailKioskPointApp3: "สมาชิก - บาร์โค้ด",
  GuideDetailKioskPointApp4: "ผู้ไม่ใช่สมาชิก - ป้อนหมายเลขโทรศัพท์",
  GuideDetailKioskPointApp5: "ผู้ไม่ใช่สมาชิก - ป้อนหมายเลขโทรศัพท์",
  GuideDetailKioskPointApp6: "เติมเงินเสร็จสิ้น",
  GuideDetailKioskKb1: "เลือกกระเป๋าเงินแห่งชาติ",
  GuideDetailKioskKb2: "เห็นด้วย",
  GuideDetailKioskKb3: "KB กระเป๋าเงินแห่งชาติ",
  GuideDetailKioskKb4: "เลือกกระเป๋าเงินแห่งชาติ",
  GuideDetailKioskKb5: "การติดแท็กบาร์โค้ด",
  GuideDetailKioskKb6: "ชาร์จเสร็จสิ้น",
  GuideDetailKioskCoin1: "เลือกเงินสดรับ KRW",
  GuideDetailKioskCoin2: "เลือกเงินทอน",
  GuideDetailKioskCoin3: "เลือกสมาชิก",
  GuideDetailKioskCoin4: "สมาชิก - บาร์โค้ด",
  GuideDetailKioskCoin5: "ผู้ไม่ใช่สมาชิก - ป้อนหมายเลขโทรศัพท์",
  GuideDetailKioskCoin6: "ผู้ไม่ใช่สมาชิก - ป้อนหมายเลขโทรศัพท์",
  GuideDetailKioskCoin7: "เติมเงินเสร็จสิ้น",
  GuideDetailKioskReturn1: "เลือกเงินสดรับ KRW",
  GuideDetailKioskReturn2: "เลือกบริจาค",
  GuideDetailKioskReturn3: "บริจาคเสร็จสิ้น",
  GuideDetailCardInfo: "บัตรเติมเงินสามารถขอรับและออกให้ได้ล่วงหน้าที่ Klook",
  GuideDetailSignUp1: "01 ลงทะเบียน",
  GuideDetailSignUp2: "02 ตกลง",
  GuideDetailSignUp3: "03 ยืนยันอีเมล",
  GuideDetailSignUp4: "04 ตั้งค่ารหัสผ่าน",
  GuideDetailSignUp5: "05 ตั้งค่ารหัสผ่านความปลอดภัย",
  GuideDetailSignUp6: "06 ลงทะเบียนเสร็จสมบูรณ์",

  GuideDetailReceiveMoneyTitle1: "รับเงินสด",
  GuideDetailReciveMoneyTitle2: "머니플렉스 앱 충전",

  GuideDetailReceiveMoneySubTitle1: "รับเงินทอน",
  GuideDetailReceiveMoney1_1: "01 เลือกรับเงินวอน",
  GuideDetailReceiveMoney1_2: "02 เลือกวิธีรับเงินทอน",
  GuideDetailReceiveMoney1_3: "03 เลือกสมาชิก/ไม่เป็นสมาชิก",
  GuideDetailReceiveMoney1_4: "04 ป้อนหมายเลข/สแกนบาร์โค้ด",
  GuideDetailReceiveMoney1_5: "05 รับเงินวอน",
  GuideDetailReceiveMoney1_6: "06 เสร็จสิ้น",

  GuideDetailReceiveMoneySubTitle2: "บริจาค (เงินทอน)",
  GuideDetailReceiveMoney2_1: "01 เลือกรับเงินสด",
  GuideDetailReceiveMoney2_2: "02 เลือกรับเงินทอน",
  GuideDetailReceiveMoney2_3: "03 เลือกบริจาค",
  GuideDetailReceiveMoney2_4: "04 เสร็จสิ้น",

  GuideDetailReceiveMoneySubTitle3: "เติมเงินแอป MoneyFlex",
  GuideDetailReceiveMoney3_1: "01 เติมเงินบัตรขนส่ง",
  GuideDetailReceiveMoney3_2: "02 เลือกสมาชิกหรือไม่เป็นสมาชิก",
  GuideDetailReceiveMoney3_3: "03 ไม่เป็นสมาชิก - ป้อนหมายเลข",
  GuideDetailReceiveMoney3_4: "04 สมาชิก - ป้อนบาร์โค้ด",
  GuideDetailReceiveMoney3_5: "05 เสร็จสิ้น",

  GuideDetailReceiveMoneySubTitle4: "กระเป๋าเงิน KB",
  GuideDetailReceiveMoney4_1: "01 เลือกกระเป๋าเงิน KB",
  GuideDetailReceiveMoney4_2: "02 ยินยอมให้ข้อมูลส่วนบุคคล",
  GuideDetailReceiveMoney4_3: "03 สแกนบาร์โค้ด",
  GuideDetailReceiveMoney4_4: "04 เติมเงินเสร็จสิ้น",

  GuideDetailReceiveMoneySubTitle5: "บริจาค (บริจาคทั้งหมด)",
  GuideDetailReceiveMoney5_1: "01 เลือกบริจาค",
  GuideDetailReceiveMoney5_2: "02 บริจาคเสร็จสิ้น",

  GuideToolTip1: "กรุณาลงทะเบียนที่แท็บคูปองด้านล่าง。\n(สำหรับชาวต่างชาติ)",
  GuideToolTip2: "ต้องตั้งค่ารหัสผ่านสมาชิกเมื่อเข้าสู่ระบบ。",
  GuideToolTip3:
    "ต้องตั้งค่ารหัสผ่านความปลอดภัย 6 หลัก。\n(เมื่อใช้งานแอปและใช้คะแนน)",
  GuideToolTip4: "สามารถเลือกวันที่หลังจาก 3 วันทำการ",
  GuideToolTip5:
    "ต้องโอนเงินจำนวนที่แลกเข้าสู่บัญชี。\nการโอนเงินก่อน 24:00 ของวันนั้น才有效。\n(เนื่องจากอัตราแลกเปลี่ยนมีการเปลี่ยนแปลง หากเกิน 24:00 จะถูกยกเลิก)",
  GuideToolTip6:
    "หากมีการขอ取消หรือขอคืนเงินหลังจากการโอนเงินเสร็จสิ้น จะมีการหักค่าธรรมเนียม 1% จากจำนวนเงินที่คืน",
  GuideToolTip7: "สมาชิก - สแกนบาร์โค้ด",
  GuideToolTip8: "ไม่เป็นสมาชิก - ป้อนหมายเลขโทรศัพท์มือถือ",
  GotoKlook: "เข้าถึง klook",
  HighlightText: "งินก่อน 24:00",
  GuideBill: "ธนบัตร",
  GuideCoin: "เหรียญ",
  GuideOldBank: "ธนบัตรเก่า",
  GuideNewBank: "ธนบัตรใหม่",
};
export const MapPageTH = {
  서울: "Seoul",
  부산: "Busan",
  경기: "Gyeonggi",
  인천: "Incheon",
  MapList: "List",
  MapMap: "Map",
  MapOpenTime: "ชั่วโมงทำการ",
  MapMapButton: "แผนที่",
  MapLength: "สาขา",
  MapForiegn: "Foriegn Exchange",
};
export const InqeryPageTH = {
  InqueryFormName: "ชื่อบริษัท",
  InqueryFormNumber: "หมายเลขติดต่อ",
  InqueryFormPrivate: "ยินยอมให้เก็บข้อมูลส่วนบุคคล",
  InqueryPlaceHolderName: "กรุณากรอกชื่อบริษัท",
  InqueryPlaceHolderNumber: "กรุณากรอกหมายเลขติดต่อ",

  InqueryPrivateText:
    "ข้อมูลที่คุณกรอกจะไม่ได้รับการนำไปใช้เพื่อวัตถุประสงค์อื่นนอกเหนือจากที่ระบุไว้",
  InqueryTitle1: "สอบถามเกี่ยวกับการนำ ",
  InqueryTitle2: "MoneyPlex มาใช้ได้ง่ายๆ เลย!",
  InqueryApply: "สมัคร",
  CSTitle: "เราสามารถช่วยอะไรคุณได้บ้าง?",
  CSPlaceHolder: "หากคุณมีคำถาม ลองค้นหาคำสำคัญดูสิครับ/ค่ะ",
  InqueryCheckText: "ยินยอมให้เก็บข้อมูลส่วนบุคคล (จำเป็น)",
  CSFAQ: "คำถามที่พบบ่อย",
  CsAnnouncements: "ประกาศ",
  CSTotal: "Toal",
  CSKiosk: "Kiosk",
  CSETC: "ETC",

  CSMoneyplex: "MoneyPlex APP",
  InquerySuccess: "การสอบถามของคุณถูกส่งเรียบร้อยแล้ว",
  CSNotFound: "ไม่พบผลลัพธ์",
  AnnounceNotFound: "ไม่มีประกาศใดๆ。",
};
