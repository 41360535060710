import {
  MainPageKO,
  MoneyPlexKO,
  GuidePageKO,
  MapPageKO,
  InqeryPageKO,
} from "./ko";
import {
  MainPageCN,
  MoneyPlexCN,
  GuidePageCN,
  MapPageCN,
  InqeryPageCN,
} from "./ch";
import {
  MainPageEN,
  MoneyPlexEN,
  GuidePageEN,
  MapPageEN,
  InqeryPageEN,
} from "./en";
import {
  MainPageJP,
  MoneyPlexJP,
  GuidePageJP,
  MapPageJP,
  InqeryPageJP,
} from "./ja";
import {
  MainPageTW,
  MoneyPlexTW,
  GuidePageTW,
  MapPageTW,
  InqeryPageTW,
} from "./tw";
import {
  MainPageTH,
  MoneyPlexTH,
  GuidePageTH,
  MapPageTH,
  InqeryPageTH,
} from "./th";

const string = {
  KR: {
    moneyplex: "머니플렉스",
    kioskLoation: "키오스크 위치",
    inquery: "빠른 도입문의",
    guide: "이용 가이드",
    event: "이벤트",
    cs: "고객지원",
    kioskSetup: "서울/경기/인천/부산 지점",
    mainKioskFind: " 가까운 환전 키오스크를 쉽게 찾아보세요",
    KioskFindDirect: "키오스크 바로 찾기",
    partner: "파트너사",
    evnet: "진행중인 이벤트",
    closeEvent: "종료된 이벤트",
    discountEvent: "환율 우대 쿠폰 받아가세요!",
    gotoList: "리스트로 가기",
    serviceTerms: "서비스 이용 약관",
    privacyTerms: "개인정보 처리 방침",
    openBankingTerms: "오픈 뱅킹 약관",
    markingTerms: "마케팅 정보 수신동의",
    companyName: "(주)코인트래빗",
    CEO: "대표 : 이종선",
    callCenter: "고객센터: +82-1833-4283 (평일오전 10:00 ~ 18:00)",
    helpEmail: "이용문의",
    companyNumber: "사업자 등록번호",
    etc1: "일반, 온라인 환전업 및 무인환전업 정식등록 제131A00690호",
    etc2: "벤처기업 인증 제 20200107270호",
    address:
      "주소: 경기도 성남시 대왕판교로 815, 기업지원허브 혁신기술존 4층 463호",

    ...MainPageKO,
    ...MoneyPlexKO,
    ...GuidePageKO,
    ...MapPageKO,
    ...InqeryPageKO,
  },
  EN: {
    moneyplex: "MoneyPlex",
    kioskLoation: "Kiosk Location",
    inquery: "Inquery",
    guide: "Guide",
    event: "Event",
    cs: "Customer Service",
    kioskSetup: "Seoul/Gyeonggi/Incheon/Busan Branches",
    mainKioskFind: "Easily find nearby currency exchange kiosks",
    KioskFindDirect: "Find Kiosk Direct",
    partner: "Partner",
    evnet: "Process events",
    closeEvent: "Closed events",
    discountEvent: "Get your currency exchange discount coupon!",
    gotoList: "Go to the list",
    serviceTerms: "Terms of Service",
    privacyTerms: "Terms of Privacy Policy",
    openBankingTerms: "Terms of Open Banking",
    markingTerms: "Consent to Receive Marketing Information",
    companyName: "(Co.)Cointravit",
    CEO: "CEO : Lee Jong-sun",
    callCenter: "Customer Center: +82-1833-4283 (Weekdays 10:00 AM ~ 6:00 PM)",
    helpEmail: "Inquiry Email",
    companyNumber: "Business Registration Number",
    etc1: "General, Online Currency Exchange and Unmanned Exchange Business Registered No. 131A00690",
    etc2: "Venture Company Certification No. 20200107270",
    address:
      "Address: 815 Daewangpangyo Road, Seongnam-si, Gyeonggi-do, 4th Floor, Room 463, Business Support Hub Innovation Technology Zone",
    ...GuidePageEN,
    ...MainPageEN,
    ...MoneyPlexEN,
    ...MapPageEN,
    ...InqeryPageEN,
  },
  CN: {
    moneyplex: "MoneyPlex",
    kioskLoation: "自助机位置",
    inquery: "快速引入咨询",
    guide: "使用指南",
    event: "活动",
    cs: "客户支持",
    kioskSetup: "首尔/京畿/仁川/釜山分店",
    mainKioskFind: "轻松找到附近的外币兑换自助机",
    KioskFindDirect: "立即查找自助机",
    partner: "合作伙伴",
    evnet: "正在进行的活动",
    closeEvent: "已结束的活动",
    discountEvent: "领取您的汇率优惠券！",
    gotoList: "去列表",
    serviceTerms: "服务条款",
    privacyTerms: "隐私政策",
    openBankingTerms: "开放银行条款",
    markingTerms: "同意接收营销信息",
    companyName: "（株）币兔",
    CEO: "CEO : 李钟宣",
    callCenter: "客服中心: +82-1833-4283 (工作日10:00 ~ 18:00)",
    helpEmail: "咨询邮箱",
    companyNumber: "企业注册号码",
    etc1: "一般、在线兑换业务及无人兑换业务正式登记 第131A00690号",
    etc2: "风险企业认证 第20200107270号",
    address: "地址: 京畿道城南市大王板桥路815，企业支持中心创新技术区4楼463号",
    ...GuidePageCN,
    ...MainPageCN,
    ...MoneyPlexCN,
    ...MapPageCN,
    ...InqeryPageCN,
  },
  TH: {
    moneyplex: "MoneyPlex",
    kioskLoation: "ตำแหน่งคีออส",
    inquery: "สอบถามการติดตั้งอย่างรวดเร็ว",
    guide: "คู่มือการใช้งาน",
    event: "กิจกรรม",
    cs: "ฝ่ายบริการลูกค้า",
    kioskSetup: "สาขาโซล/คยองกี/อินชอน/ปูซาน",
    mainKioskFind: "ค้นหาตู้แลกเปลี่ยนเงินตราที่อยู่ใกล้คุณได้อย่างง่ายดาย",
    KioskFindDirect: "ค้นหาคีออสทันที",
    partner: "คู่ค้า",
    evnet: "กิจกรรมที่กำลังดำเนินอยู่",
    closeEvent: "กิจกรรมที่สิ้นสุดแล้ว",
    discountEvent: "รับคูปองส่วนลดอัตราแลกเปลี่ยน!",
    gotoList: "ไปที่รายการ",
    serviceTerms: " ข้อตกลงการใช้บริการ",
    privacyTerms: "นโยบายความเป็นส่วนตัว",
    openBankingTerms: "ข้อตกลงการใช้บริการธนาคารเปิด",
    markingTerms: "ความยินยอมรับข้อมูลการตลาด",
    companyName: "(บริษัท) คอยน์แรบบิท",
    CEO: "CEO : อีจงซอน",
    callCenter:
      "ศูนย์บริการลูกค้า: +82-1833-4283 (จันทร์ถึงศุกร์ 10:00 ~ 18:00)",
    helpEmail: "อีเมลสอบถาม",
    companyNumber: "หมายเลขจดทะเบียนธุรกิจ",
    etc1: "ทั่วไป, ธุรกิจแลกเปลี่ยนออนไลน์และธุรกิจแลกเปลี่ยนแบบไม่คนกำกับการทะเบียนอย่างเป็นทางการ เลขที่ 131A00690",
    etc2: "การรับรองบริษัทเริ่มต้น เลขที่ 20200107270",
    address:
      "ที่อยู่: 815 ถนนแดวังพานกยอ, จังหวัดคยองกี, ชั้น 4 ห้อง 463 เขตเทคโนโลยีนวัตกรรมศูนย์สนับสนุนธุรกิจ",
    ...GuidePageTH,
    ...MainPageTH,
    ...MoneyPlexTH,
    ...MapPageTH,
    ...InqeryPageTH,
  },
  CT: {
    moneyplex: "MoneyPlex",
    kioskLoation: "自助機位置",
    inquery: "快速引入諮詢",
    guide: "使用指南",
    event: "活動",
    cs: "客戶支持",
    kioskSetup: "首爾/京畿/仁川/釜山分店",
    mainKioskFind: "輕鬆找到附近的外幣兌換自助機",
    KioskFindDirect: "立即查找自助機",
    partner: "合作夥伴",
    evnet: "正在進行的活動",
    closeEvent: "已結束的活動",
    discountEvent: "領取您的匯率優惠券！",
    gotoList: "去列表",
    serviceTerms: "服務條款",
    privacyTerms: "隱私政策",
    openBankingTerms: "開放銀行條款",
    markingTerms: "同意接收行銷資訊",
    companyName: "（株）幣兔",
    CEO: "CEO : 李鐘宣",
    callCenter: "客服中心: +82-1833-4283 (工作日10:00 ~ 18:00)",
    helpEmail: "諮詢郵箱",
    companyNumber: "企業登記號碼",
    etc1: "一般、在線兌換業務及無人兌換業務正式登記 第131A00690號",
    etc2: "風險企業認證 第20200107270號",
    address: "地址: 京畿道城南市大王板橋路815，企業支持中心創新技術區4樓463號",
    ...MainPageTW,
    ...MoneyPlexTW,
    ...GuidePageTW,
    ...MapPageTW,
    ...InqeryPageTW,
  },
  JP: {
    moneyplex: "MoneyPlex",
    kioskLoation: "キオスク位置",
    inquery: "迅速導入のお問い合わせ",
    guide: "利用ガイド",
    event: "イベント",
    cs: "カスタマーサポート",
    kioskSetup: "ソウル/京畿/仁川/釜山支店",
    mainKioskFind: "近くの外貨両替キオスクを簡単に探しましょう",
    KioskFindDirect: "キオスクをすぐに探す",
    partner: "パートナー",
    evnet: "現在進行中のイベント",
    closeEvent: "終了したイベント",
    discountEvent: "為替レートの割引クーポンをゲット！",
    gotoList: "リストに行く",
    serviceTerms: "サービス利用規約",
    privacyTerms: "プライバシーポリシー",
    openBankingTerms: "オープンバンキング利用規約",
    markingTerms: "マーケティング情報の受信同意",
    companyName: "株式会社コイントラビット",
    CEO: "CEO : イ・ジョンソン",
    callCenter: "カスタマーセンター: +82-1833-4283 (平日10:00〜18:00)",
    helpEmail: "お問い合わせ",
    companyNumber: "事業者登録番号",
    etc1: "一般、オンライン両替業および無人両替業 正式登録 第131A00690号",
    etc2: "ベンチャー企業認証 第20200107270号",
    address:
      "住所: 京畿道城南市大王板橋路815、企業支援ハブ革新技術ゾーン4階463号",
    ...GuidePageJP,
    ...MainPageJP,
    ...MoneyPlexJP,
    ...MapPageJP,
    ...InqeryPageJP,
  },
};

export default string;
