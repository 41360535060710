import React from "react";
import useWindowSizeCustom from "hooks/useWindowSizeCustom";
import MobileBackground from "asset/image/MobileBackground.png";
import MessageIcon from "asset/image/messageIcon.png";
import {
  fontSize24,
  fontSize26,
  fontSize30,
  fontSize32,
  leading50,
} from "const/fontSize";
import Background from "asset/image/Background.png";
import { Link } from "react-router-dom";

export default function InqueryBanner({
  body1Text,
  body2Text,
  inqueryText,
}: {
  body1Text: string;
  body2Text?: string;
  inqueryText: string;
}) {
  const widthSize = useWindowSizeCustom();
  return widthSize.width < 800 ? (
    <div
      className="w-full flex flex-col items-center justify-between py-20 px-24 "
      style={{
        backgroundImage: `url(${MobileBackground})`,
        backgroundSize: "cover",
        backgroundPosition: "center center",
        marginTop: 30,
      }}
    >
      <img src={MessageIcon} width={40} />
      <span className="ml-[25px] my-13 text-12 text-center text-white font-bold leading-20">
        {body1Text}
      </span>
      <a className="w-full" href={"/inquery"}>
        <button className="w-full bg-white  rounded-[50px] text-14 font-bold text-primary p-10 h-40">
          {inqueryText}
        </button>
      </a>
    </div>
  ) : (
    <div className=" relative mt-80 px-[88px] text-center">
      <div
        className="top-0 relative w-full max-w-[1500px] justify-around flex flex-row items-center px-[5%] py-[1.5%] 2xl:h-[100%] xl:h-[250px] "
        style={{
          backgroundImage: `url(${Background})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          borderRadius: "80px 80px 0px 0px",
        }}
      >
        <div className="flex flex-row w-[50%] ">
          <img
            className="2xl:w-158 xl:w-100 lg:w-75 md:w-50 sm:w-25"
            style={{
              objectFit: "contain",
            }}
            src={MessageIcon}
          />
          <span
            className={`${fontSize24} ml-25 mt-20  text-white ${leading50} font-bold`}
          >
            {body1Text}
          </span>
        </div>
        <Link className="" to={"/inquery"}>
          <button
            className={` w-[100%] 2xl:min-w-[300px] 2xl:w-[100%] xl:min-w-[250px] lg:min-w-[200px] md:min-w-150   ${fontSize30} text-nowrap bg-white  rounded-[50px] py-20 px-10  font-bold text-primary`}
          >
            {inqueryText}
          </button>
        </Link>
      </div>
    </div>
  );
}
