import { fontSize32, fontSize24, fontSize40 } from "const/fontSize";
import React from "react";
import KioskImg1 from "asset/image/KioskImg1.png";
import KioskImg2 from "asset/image/KioskImg2.png";
import KioskImg3 from "asset/image/KioskImg3.png";
import string from "locales";
import { LanguageProps } from "types/Type";
import InqueryBanner from "component/common/InqueryBanner";

export default function B2BInfo3({ userLanguage }: LanguageProps) {
  return (
    <div className="bg-[#F4F7FB] flex flex-col justify-center items-center">
      <div className=" bg-[#F4F7FB] px-24 sm:px-[20%]">
        <div
          className={` text-center ${fontSize40} font-bold text-primary pt-31 mb-27 sm:pt-100 sm:mb-60`}
        >
          {string[userLanguage].MoneyplexKioskExampleTitle}
        </div>
        <div className="grid grid-cols-2 gap-y-20 gap-x-10 sm:gap-y-30  w-full lg:flex lg:flex-row sm:gap-x-30 lg:mt-30">
          <div className="max-w-[500px]">
            <div>
              <img
                style={{
                  objectFit: "cover",
                  borderRadius: 10,
                }}
                className="lg:mb-10 sm:w-[380px] lg:max-w-[500px] "
                src={KioskImg1}
              />
            </div>
            <div
              className={`text-12 ${fontSize32} text-center leading-18 sm:leading-50 lg:mt-13 `}
            >
              {string[userLanguage].MoneyplexKioskExamplelocation1}
            </div>
            <div className={`text-10 ${fontSize24} text-[#636363] text-center`}>
              {string[userLanguage].MoneyplexKioskExamplebody1}
            </div>
          </div>
          <div className="   max-w-[500px]">
            <div>
              <img
                style={{
                  objectFit: "cover",
                  borderRadius: 10,
                }}
                className="lg:mb-10 sm:w-[380px] lg:max-w-[500px] "
                src={KioskImg2}
              />
            </div>
            <div
              className={`text-12 ${fontSize32} text-center leading-18 sm:leading-50 lg:mt-13 `}
            >
              {string[userLanguage].MoneyplexKioskExamplelocation2}
            </div>
            <div className={`text-10 ${fontSize24} text-[#636363] text-center`}>
              {string[userLanguage].MoneyplexKioskExamplebody2}
            </div>
          </div>
          <div className="max-w-[500px]">
            <div>
              <img
                style={{
                  objectFit: "cover",
                  borderRadius: 10,
                }}
                className="lg:mb-10 sm:w-[380px] lg:max-w-[500px] "
                src={KioskImg3}
              />
            </div>
            <div
              className={`text-12 ${fontSize32} text-center leading-18 sm:leading-50 lg:mt-13 `}
            >
              {string[userLanguage].MoneyplexKioskExamplelocation3}
            </div>
            <div className={`text-10 ${fontSize24} text-[#636363] text-center`}>
              {string[userLanguage].MoneyplexKioskExamplebody3}
            </div>
          </div>
        </div>
      </div>
      <InqueryBanner
        body1Text={string[userLanguage].MainpartnerSectionBody1}
        body2Text={string[userLanguage].MainpartnerSectionBody2}
        inqueryText={string[userLanguage].MainpartnerSectionInqueryButton}
      />
    </div>
  );
}
