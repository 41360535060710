export default function TabButton({
  currentTab,
  setCurrentTab,
  leftText,
  rightText,
}: {
  currentTab: string;
  setCurrentTab: any;
  leftText: string;
  rightText: string;
}) {
  return (
    <div className='flex flex-row w-full items-center justify-center'>
      <div className='flex flex-row w-full sm:w-[600px] items-center justify-center h-50 sm:h-[75px] bg-[#3F3F3F] rounded-8 bg-opacity-15 text-16 sm:text-24 text-[#3F3F3F] font-medium'>
        <div
          onClick={() => {
            setCurrentTab(leftText);
          }}
          style={{
            backgroundColor: currentTab === leftText ? '#FFFFFF' : '',

            boxShadow:
              currentTab === leftText
                ? '0px 3px 8px 0px rgba(0, 0, 0, 0.12), 0px 3px 1px 0px rgba(0, 0, 0, 0.04)'
                : '',
          }}
          className='ml-[2px] flex justify-center text-[#1E2637]  w-[50%] h-50 sm:h-[75px]  items-center text-center  rounded-[8px]'
        >
          {leftText}
        </div>
        <div
          style={{
            backgroundColor: currentTab === rightText ? '#FFFFFF' : '',
            boxShadow:
              currentTab === rightText
                ? '0px 3px 8px 0px rgba(0, 0, 0, 0.12), 0px 3px 1px 0px rgba(0, 0, 0, 0.04)'
                : '',
          }}
          onClick={() => {
            setCurrentTab(rightText);
          }}
          className='flex justify-center w-[50%] h-50 sm:h-[75px] mt-[2px] items-center text-center rounded-[8px] mr-[2px]'
        >
          {rightText}
        </div>
      </div>
    </div>
  );
}
